<template>
  <div class="prime-modal">
    <ion-header class="bottom-divider-v2">
      <ion-toolbar>
        <h5 slot="start">Quality Consideration Details</h5>
        <ion-button slot="end" mode="ios" @click="closeModal()" expand="full" color="medium" fill="clear"><ion-icon
            name="md-close"></ion-icon></ion-button>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <ion-grid class="full-height">
        <ion-row class="full-height pad-left-twenty" style="overflow: hidden;">
          <ion-col class="tabs-divider pad-top-twenty" style="margin: 0; width: 50%; max-height: 100%">
            <div>
              <ion-row class="margin-bottom-ten pad-bottom-ten bottom-divider-v2 margin-right-twenty">
                <ion-col>
                  <div class="text-bold text-13 text-black">
                    {{ item.rec_title }}
                  </div>
                </ion-col>
              </ion-row>

              <ion-row class="pad-bottom-ten margin-right-ten">
                <ion-col>
                  <div class="text-small text-muted margin-bottom-five">
                    Instructions
                  </div>
                  <div class="text-12 text-semibold">
                    {{ item.rec_msg }}
                  </div>

                  <ion-row class="margin-bottom-ten margin-top-twenty">
                    <ion-col v-if="item.fact_desc" class="flex-1-5">
                      <supporting-facts 
                        :last-reported-date="item.fact_date" 
                        :supporting-fact="item.fact_desc"
                        :provider-name="item.provider_name" 
                        :provider-type="item.specialty"
                        date-format-string="MMMM DD, YYYY" class="modal-sp">
                      </supporting-facts>
                    </ion-col>

                    <ion-col class="margin-left-ten">
                      <div class="text-small text-muted">Type</div>
                      <div>
                        <span v-if="item.is_contract_measure === 'Y'"
                          class="text-center maxed-pill text-bold pill pill-blue margin-top-five">Contract</span>
                        <span v-else
                          class="text-center maxed-pill text-bold pill gray-pill margin-top-five">Non-contract</span>
                      </div>
                    </ion-col>

                  </ion-row>
                </ion-col>
              </ion-row>

              <ion-row class="bottom-divider-v2" style="margin-left: -20px;"></ion-row>
            </div>

            <div style="height: 80%;">
              <div class="notes-heading" style="padding-bottom: 0px;">
                <h6 style="font-size: 14.5px;">
                  <strong>Notes and History:</strong>
                </h6>
              </div>
              <div class="notes-section pad-left-twenty" style="margin-left: -20px; height: 80%;">
                <div style="height: 370px;" class="pad-top-twenty margin-right-ten">
                  <ul id="notes" class="notes">
                    <li v-for="(note, index) in notes" :key="index" class="pad-bottom-ten bottom-divider-v2">
                      <div class="note text-13 text-bold">
                        “{{ note.body }}”
                      </div>
                      <span class="meta">{{ note.createdAt | moment("M/DD/YYYY - h:mmA") }} - </span>
                      <span class="by text-bold">{{ note.createdBy }} </span>
                      <div style="display: none" class="message-actions cursor-pointer"
                        @click="showCommentActions($event, note.body)">
                        <ion-icon color="primary" name="ios-more" />
                      </div>
                    </li>
                  </ul>
                  <div class="note-input pad-bottom-ten">
                    <ion-grid>
                      <ion-row class="ion-align-items-center" style="position: absolute; bottom: 0px; width: 100%;">
                        <ion-col class="vgt-wrap">
                          <textarea-autosize placeholder="Leave a note or comment.."
                            class="form-control prime-message-input" rows="1" v-model="newNote" :min-height="65"
                            :max-height="350" style="padding-top: 20px; padding-right: 60px;"
                            @keydown.native.enter.exact.prevent @keyup.native.enter.exact="updateInfo('No Change')"
                            @keydown.native.enter.shift.exact="newline" />
                          <button type="button" class="prime-button button-secondary send-message-button add-notes-btn"
                            @click="updateInfo('No Change')">
                            <div>
                              <ion-icon name="arrow-up" size="large" :color="'primary'" mode="md"></ion-icon>
                            </div>
                            <ion-spinner name="dots" duration="1200" color="white"></ion-spinner>
                          </button>
                        </ion-col>
                      </ion-row>
                    </ion-grid>
                  </div>
                </div>
              </div>
            </div>
          </ion-col>

          <ion-col class="editing-content" :class="{ disabled: !canUpdateGapsInCare }"
            style="width:50%; height: calc(100vh - 100px)">
            <div v-if="isMarkedStatusEnabled" class="v-align notes-heading ">
              <ion-row class="valign pad-top-ten pad-bottom-ten bottom-divider-v2">
                <ion-col class=" pad-right-ten">
                  <div class="text-small text-muted margin-bottom-five pad-ten margin-left-five">
                    Consideration Status
                  </div>
                </ion-col>

                <ion-col class="margin-right-five pad-ten">
                  <ion-segment mode="ios" :value="checkStatus"
                    class="prime-channel-tabs-container ion-segment-button-small margin-bottom-five">
                    <ion-segment-button @click="setAddressedStatus(true)" layout="icon-start" value="addressed"
                      class="prime-channel-tab" mode="ios" :disabled="item.markedStatusChangeEnabled == false">
                      Addressed
                    </ion-segment-button>
                    <ion-segment-button @click="setAddressedStatus(false)" layout="icon-start" value="not_addressed"
                      mode="ios" :disabled="item.markedStatusChangeEnabled == false">
                      Not Addressed
                    </ion-segment-button>
                  </ion-segment>
                </ion-col>
              </ion-row>
            </div>

            <div class="custom-scroll-fix">

              <!-- {{ notes }} -->
              <div v-if="item.eligibleForCde" class="notes-heading pad-ten bottom-divider-v2">
                <ion-toolbar class="adjusted-height-toolbar">
                  <div class="display-flex">
                    <div class="display-flex flex-2">
                      <div class="text-small text-muted margin-top-five margin-left-five">
                        CDE Status
                      </div>
                    </div>
                    <div v-if="item.cdeStatus" class="text-center text-bold pill text-capitalize"
                      :class="getCdeStatus(item.cdeStatus).displayClass">{{ getCdeStatus(item.cdeStatus).displayName }}
                    </div>
                  </div>
                </ion-toolbar>
                <ion-row class="pad-top-twenty margin-bottom-ten">
                  <ion-col class="pad-five">
                    <button v-if="$can(I.VIEW_CDE_FORM)" type="button" class="prime-button button-block button-primary"
                      @click="openModalClinicalData">{{ getCdeStatus(item.cdeStatus).displayText }}</button>
                    <button v-else type="button" class="prime-button disabled button-block button-primary"
                      v-tooltip.top-start="{ content: $orgMissingPermissions('The View CDE feature') }">{{
          getCdeStatus(item.cdeStatus).displayText }}</button>
                  </ion-col>
                  <ion-col class="pad-five" v-if="item.cdeStatus !== 'ready_for_entry'" size="6">
                    <button v-if="$can(I.ADD_CDE_REQUEST)" type="button"
                      class="prime-button button-block button-secondary"
                      @click="openModalClearClinicalData(item.cdeRequestId)">
                      <IconDeleteTrash slot="end" class="icon-small icon-muted" />Clear Entered Clinical Data
                    </button>
                    <button v-else type="button" class="prime-button button-block button-primary disabled"
                      v-tooltip.top-start="{ content: $orgMissingPermissions('The Modify CDE feature') }">
                      <IconDeleteTrash slot="end" class="icon-small icon-muted" />Clear Entered Clinical Data
                    </button>

                  </ion-col>
                </ion-row>
              </div>
              <div class="action-button">
                <ion-footer>
                  <ion-row>
                    <ion-col class="text-center">
                      <button @click="closeModal()" class="prime-button button-secondary full-width">
                        Cancel
                      </button>
                    </ion-col>
                    <ion-col class="text-center margin-left-ten">
                      <button @click="saveRecords()" class="prime-button button-primary button-block"
                        :disabled="!isMarkedStatusEnabled">
                        Save
                      </button>
                    </ion-col>
                  </ion-row>
                </ion-footer>


              </div>
            </div>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-content>

    <ion-content v-if="contentType === 'Mark as Rejected' || contentType === 'Cancel Rejection'"
      class="prime-modal-content">
      <p>Please provide a note explaining why this Gap in Care is not valid or actionable:</p>
      <div class="marking-div">
        <div v-show="errorMsg" class="notifications-well danger-well">
          {{ errorMsg }}
        </div>
        <textarea v-model="newNote" placeholder="Type note here..."
          class="form-control prime-message-input rejection-note"></textarea>
      </div>
    </ion-content>
  </div>
</template>

<script>
import { send as httpSend } from "@/services/Api";
import { can, I } from "@/utils/permissions";
import { EventBus } from "@/services/Events";
import PopoverCommentActions from "@/components/TaskDetails/PopoverCommentActions";
import ModalEditTextArea from "@/components/Global/ModalEditTextArea";
import ModalClinicalData from "./ModalClinicalData.vue";
import ModalClearClinicalData from "./ModalClearClinicalData.vue";

import IconDeleteTrash from "@/components/Global/Icons/IconDeleteTrash";
import IconEditPencilFramed from "@/components/Global/Icons/IconEditPencilFramed.vue";

export default {
  name: "ModalGapsInCare",
  components: {
    IconEditPencilFramed,
    IconDeleteTrash
  },
  props: {
    beneId: Number,
    title: String,
    item: Object,
    addressed: String,
    canUpdateGapsInCare: Boolean,
    currentPatient: Object
  },
  data() {
    return {
      prevContentType: "",
      contentType: "",
      isMessageSending: false,
      notes: [],
      isMarkedStatusEnabled: true,
      canUpdateGapsInCare: true,
      isRejected: false,
      updatedStatus: "",
      newNote: "",
      errorMsg: "",
      noData: false,
      selectedComment: "",
      hasClearedData: false,
      viewOnly: false,
      isAddressed: null,
      isAddressedStatus: null,
      initialStateToSave: false,
    };
  },
  beforeMount() {
    EventBus.$on("updatingGap", () => {
      EventBus.$emit("updateQualityConsideration");
      this.$destroy();
      this.closeModal();
    });
    EventBus.$on("clearingData", () => {
      this.hasClearedData = true;
      this.closeModal();
      this.$destroy();
    });
  },
  async mounted() {
    if (!can(I.MODIFY_CDE_REQUEST)) {
      this.viewOnly = true;
    }
    this.contentType = this.title;
    this.isLoading = true;
    this.isRejected = this.item.rejected;
    if (this.contentType === "Notes") await this.getNotes();
    this.contentType === "Notes" ? this.updateScrollBarLocation() : "";
    this.isAddressedStatus = this.item.markedStatus ? this.item.markedStatus.toLowerCase() : null;

    this.addressed = this.addressed ? this.addressed.toLowerCase() : null;

    if ((this.isAddressedStatus === "addressed" || this.isAddressedStatus === null) && this.addressed === "addressed") {
      this.isAddressed = true;
    } else if ((this.isAddressedStatus === "addressed" || this.isAddressedStatus === null) && this.addressed === "not_addressed") {
      this.isAddressed = false;
    } else if ((this.isAddressedStatus === "not_addressed" || this.isAddressedStatus === null) && this.addressed === "addressed") {
      this.isAddressed = true;
    } else if ((this.isAddressedStatus === "not_addressed" || this.isAddressedStatus === null) && this.addressed === "not_addressed") {
      this.isAddressed = false;
    } else if ((this.isAddressedStatus === "not_specified" || this.isAddressedStatus === null) && this.addressed === "addressed") {
      this.isAddressed = true;
    } else if ((this.isAddressedStatus === "not_specified" || this.isAddressedStatus === null) && this.addressed === "not_addressed") {
      this.isAddressed = false;
    }

  },
  destroyed() {
    EventBus.$off("updatingGap");
    if (this.hasClearedData) EventBus.$emit("updateQualityConsideration");
  },
  computed: {
    checkStatus() {
      let result;
      if (this.isAddressed === true) {
        result = "addressed";
      } else if (this.isAddressed === false) {
        result = "not_addressed";
      } else {
        result = null;
      }
      return result;
    }
  },
  methods: {
    async getNotes() {
      const method = "get";
      const path = document.config.gapsInCare + this.beneId + "/gap/" + this.item.gapId;

      return httpSend({ path, method, authToken: true })
        .then(async response => {
          // first remove Text Triage by id
          const responseObject = response.data
          this.notes = responseObject.recordNotes.notes;
          this.isMarkedStatusEnabled = responseObject.isMarkedStatusEnabled;
          this.canUpdateGapsInCare = responseObject.isMarkedStatusEditable
          this.isLoading = false;
          return response;
        })
        .catch(error => {
          this.isLoading = false;
          this.noData = true;
          khanSolo.log(error);
        });
    },
    async saveRecords() {
      this.errorMsg = "";
      const method = "post";
      let payload = {};
      const path = document.config.gapsInCare + this.beneId + "/markedStatus/" + this.item.gapId;
      if (this.isAddressed) {
        payload = {
          markedStatus: 'addressed'
        }
      } else if (this.isAddressed === false) {
        payload = {
          markedStatus: 'not_addressed'
        }
      } else {
        payload = {
          markedStatus: 'not_specified'
        }
      }
      return httpSend({ path, method, payload, authToken: true })
        .then(async () => {
          this.isLoading = false;
          EventBus.$emit("updateStatus");
          this.closeModal();
        })
        .catch(error => {
          this.$ionic.toastController
            .create({
              header: "Failed to update the status",
              message: error,
              duration: 7000,
              position: "top"
            })
            .then(m => m.present());
          khanSolo.log(error);
        });
    },
    updateInfo(uStatus) {
      if (this.newNote === "") {
        this.errorMsg = "Please provide a message";
        return;
      }
      this.errorMsg = "";
      const method = "post";
      const path = document.config.gapsInCare + this.beneId + "/gap/" + this.item.gapId;
      const payload = {
        status: uStatus,
        note: this.newNote
      };

      httpSend({ path, method, payload, authToken: true })
        .then(async () => {
          this.successChange = true;
          const allNotesResp = await this.getNotes();
          this.newNote = "";
          this.noData = false;
          EventBus.$emit("updateStatus", { itemId: this.item.gapId, rejected: allNotesResp.data.rejected });
          EventBus.$emit("updateNotesCount", { gapId: this.item.gapId, notesCount: allNotesResp.data.recordNotes.notes.length });
          if (uStatus === "No Change") {
            this.updateScrollBarLocation();
          } else {
            this.closeModal();
          }
        })
        .catch(error => {
          this.successChange = false;
          this.$ionic.toastController
            .create({
              header: "Failed to load Quality Considerations",
              message: error,
              duration: 7000,
              position: "top"
            })
            .then(m => m.present());
          khanSolo.log(error);
        });
    },
    updateScrollBarLocation() {
      const notesSection = document.querySelector("#notes");
      notesSection.scrollTop = notesSection.scrollHeight;
    },
    closeModal() {
      EventBus.$emit("initializeListIndicators");
      this.$ionic.modalController.dismiss();
    },
    getCdeStatus(cdeStatus) {
      const cdeStatusDictionary = {
        ready_for_entry: { cssClass: "pill-blue", text: this.viewOnly ? "View Clinical Data" : "Enter Clinical Data" },
        first_review_needed: { cssClass: "gray-pill review-needed", text: "Clinical Data - First Review" },
        second_review_needed: { cssClass: "gray-pill review-needed", text: "Clinical Data - Second Review" },
        in_first_review: { cssClass: "gray-pill review-needed", text: "Clinical Data - In Review" },
        in_second_review: { cssClass: "gray-pill review-needed", text: "Clinical Data - In Review" },
        approved: { cssClass: "pill-wrap-green", text: "View Clinical Data" },
        rejected: { cssClass: "pill-wrap-urgent", text: "View Clinical Data" }
      };

      return {
        displayName: cdeStatus.replaceAll("_", " "),
        displayClass: cdeStatusDictionary[cdeStatus].cssClass,
        displayText: cdeStatusDictionary[cdeStatus].text
      };
    },
    actions() {
      return {
        events: {
          edit: () => {
            this.editNoteModal(this.selectedComment, this.selectedNoteId);
          }
        }
      };
    },
    showCommentActions(ev, comment) {
      // eslint-disable-next-line
      this.selectedComment = comment;
      this.selectedNoteId = 12;

      this.$ionic.popoverController
        .create({
          component: PopoverCommentActions,
          event: ev,
          componentProps: {
            data: {
              events: this.actions().events
            }
          },
          mode: "ios",
          cssClass: "popover-width-sm"
        })
        .then(p => p.present());
    },
    editNoteModal(comment, id) {
      this.$ionic.modalController
        .create({
          component: ModalEditTextArea,
          componentProps: {
            propsData: {
              title: "Edit Comment",
              instructions: "Edit your comment here and then click 'Save Edit' below.",
              value: comment,
              id: id,
              onSave: this.editComment,
              validators: [value => (!value ? "Please enter comment text" : null)]
            }
          }
        })
        .then(m => m.present());
    },
    editComment(newComment, id) {
      this.commentError = false;
      if (!newComment) {
        this.commentError = "Please enter comment text";
        return;
      }

      const method = "put";
      const path = document.config.task + "comment/" + id;

      let payload = {
        comment: newComment
      };

      this.isSavingComment = true;

      httpSend({ path, method, payload, authToken: true })
        .then(() => {
          this.getComments(this.data.task.id);
          this.selectedComment = undefined;
        })
        .catch(error => {
          this.catchError(error, "Could not edit comment. Please try again later.");
        })
        .finally(() => (this.isSavingComment = false));
    },
    openModalClinicalData() {
      this.$ionic.modalController
        .create({
          component: ModalClinicalData,
          mode: "ios",
          cssClass: "prime-modal-xwide",
          componentProps: {
            propsData: {
              beneId: this.beneId,
              item: this.item
            }
          },
          backdropDismiss: false
        })
        .then(m => m.present());
    },
    /**
    * Sets the status of the record
    */
    setAddressedStatus(bool) {
      this.initialStateToSave = true;

      if ((this.isAddressed === true && bool === true) || (this.isAddressed === false && bool === false)) {
        setTimeout(() => {
          this.isAddressed = null;
          this.initialStateToSave = false;
        }, 50);
      } else {
        this.isAddressed = bool;
      }
    },
    openModalClearClinicalData(rqstId) {
      this.$ionic.popoverController
        .create({
          component: ModalClearClinicalData,
          mode: "ios",
          cssClass: "popover-width-lg",
          componentProps: {
            propsData: {
              requestId: rqstId
            }
          },
          backdropDismiss: false
        })
        .then(m => m.present());
    }
  }
};
</script>

<style scoped>
.tabs-divider {
  border-right: 2px solid var(--ion-color-light);
}

.prime-modal-content {
  border: none;
  padding: 0 20px 5px;
}

.message {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
}

ion-footer {
  padding: 20px;
}

.editing-content {
  opacity: 1;
  transition: all 0.2s ease-in-out;

  &.disabled {
    opacity: 0.6;
    pointer-events: none;
  }

  .content-scroll {
    overflow-y: scroll;
    height: 580px;
    padding: 15px 20px 20px 0;
  }
}

.meta {
  color: var(--ion-color-medium-shade);
  font-size: smaller;
}

.by {
  font-size: smaller;
}

.custom-scroll-fix {
  overflow: auto;
  position: relative;
  height: 530px;
}

.notes {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow-y: auto;
  height: 250px;
}

.notes li {
  margin-bottom: 20px;
}

.add-notes-btn {
  position: absolute;
  top: 15px;
  right: 10px;
  height: 35px;
}

.note-input {
  position: absolute;
  bottom: 40px;
  width: 97%;
}

.action-button {
  position: absolute;
  bottom: 0px;
  width: 100%;
}

.rejection-note {
  height: 100%;
}

.notifications-well {
  width: 100%;
}

.marking-div {
  height: 85%;
}

ion-content {
  --padding-bottom: 0;
  --padding-end: 0;
  --padding-start: 0;
}

.notes-section {
  background: var(--ion-background-secondary-color);
}

.notes-heading {
  background: white;
  border-top: 1px solid transparent;
  padding-bottom: 10px;
}

.shadowed {
  box-shadow: 0 4px 4px -2px rgba(46, 61, 73, 0.1);
}

.message-actions {
  display: inline-block;
  height: 13px;
  margin-left: 2px;
}

.adjusted-height-toolbar {
  --min-height: 20px;
}

.pill {
  flex: 1 1 0%;
  height: 24px;
  font-size: 11px;
  text-align: center;
  padding: 4px;
}

.gray-pill {
  color: black;
}

.review-needed {
  border: 2px solid rgb(255, 164, 61);
  padding: 1px;
}

.pill-wrap-green {
  background-color: #70c01c;
  color: #fff;
}

.pill-wrap-urgent {
  background-color: #e02020;
  color: #fff;
}

.maxed-pill {
  max-width: 130px;
}
</style>
