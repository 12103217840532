<template>
    <ion-col>
        <ion-card color="white" class="margin-death overflow-visible pad-left-zero pad-right-zero full-width"
            :class="isCarded ? 'flatten-card' : ''">
            <ion-card-content>
                <ion-grid>
                    <ion-row class="qc-inner pad-left-fifteen pad-right-fifteen">
                        <ion-col
                            @click="openModalGaps(beneId, 'Notes', item, item.markedStatus, canUpdateGapsInCare, currentPatient, isCarded)"
                            class="name cursor-pointer">

                            <ion-row>
                                <span class="title margin-bottom-zero">{{ item.rec_title }}</span>
                            </ion-row>
                            <ion-row size="auto" class="notes valign cursor-pointer margin-top-two"
                                style="align-items: flex-start">
                                <ion-col v-if="!isCarded" class="qc-notes display-flex flex-0 left-align">
                                    <span
                                        class="display-flex text-small text-muted text-semibold margin-right-five valign">Notes:
                                    </span>
                                    <div v-if="item.countOfNotes > 0" class="pill-blue pill-sm margin-right-ten">
                                        {{ item.countOfNotes }}</div>
                                    <div v-else class="pill-gray-dark pill-sm text-grey margin-right-ten">0
                                    </div>
                                </ion-col>

                                <ion-col v-if="item.rec_msg && !isCarded"
                                    class="qc-instructions flex-0 display-flex valign" size="auto"
                                    v-tooltip.top="item.rec_msg">
                                    <div
                                        class="text-small text-blue text-bold margin-right-eight pill-gray-dark pill-mm">
                                        Instructions</div>
                                </ion-col>
                                <ion-col>
                                    <supporting-facts :last-reported-date="item.fact_date"
                                        :supporting-fact="item.fact_desc" :provider-name="item.provider_name"
                                        :provider-type="item.specialty" date-format-string="MMMM DD, YYYY"
                                        :class="'overview-sp'"></supporting-facts>
                                </ion-col>
                            </ion-row>
                        </ion-col>

                        <ion-col v-if="!isCarded" size="auto" class="valign" style="align-self: center;">
                            <ion-row>
                                <ion-col>
                                    <ion-row class="ion-align-items-center">
                                        <ion-col v-if="!isCarded && item.cdeStatus" size="auto">
                                            <span class="notes text-small text-bold margin-right-five">CDE Status:
                                            </span>
                                        </ion-col>
                                        <ion-col>
                                            <div v-if="item.cdeStatus"
                                                class="text-center text-bold pill text-capitalize margin-right-ten"
                                                :class="getCdeStatus(item.cdeStatus).displayClass">
                                                {{ getCdeStatus(item.cdeStatus).displayName }}</div>
                                        </ion-col>
                                        <ion-col v-if="!isCarded" class="margin-left-ten">
                                            <span v-if="item.is_contract_measure === 'Y'"
                                                class="text-center text-bold pill pill-blue">Contract</span>
                                            <span v-else
                                                class="text-center text-small text-bold pill">Non-contract</span><br />
                                        </ion-col>
                                    </ion-row>
                                </ion-col>
                            </ion-row>
                        </ion-col>

                        <ion-col v-if="!isCarded && isMarkedStatusEnabled" class="valign margin-left-ten"
                            style="align-self: center;" :class="classes ? 'segment-small' : 'flex-0'">
                            <ion-segment mode="ios"
                                :value="(item.markedStatus ? item.markedStatus.toLowerCase() : 'not_specified')"
                                class="prime-channel-tabs-container ion-segment-button-small editing-content"
                                :class="{ disabled: !canUpdateGapsInCare }">
                                <ion-segment-button @click="markedAddressNotAddress(beneId, item, 'addressed')"
                                    layout="icon-start" value="addressed" class="prime-channel-tab" mode="ios"
                                    :disabled="item.markedStatusChangeEnabled == false">
                                    <div>
                                        <span>Addressed</span>
                                    </div>
                                </ion-segment-button>
                                <ion-segment-button @click="markedAddressNotAddress(beneId, item, 'not_addressed')"
                                    layout="icon-start" value="not_addressed" mode="ios"
                                    :disabled="item.markedStatusChangeEnabled == false">
                                    <div>
                                        <span>Not Addressed</span>
                                    </div>
                                </ion-segment-button>
                            </ion-segment>
                        </ion-col>

                        <ion-row v-if="isCarded && isMarkedStatusEnabled" class="valign margin-left-ten add-notes-btn">
                            <ion-segment mode="ios" :value="checkAddressStatus(item)"
                                class="prime-channel-tabs-container add-notaddress-tabs-container checkmark-crossmark editing-content"
                                :class="{ disabled: !canUpdateGapsInCare }">
                                <ion-segment-button
                                    @click="updateAddressNotAddressFromDashboard(beneId, item, 'addressed')"
                                    layout="icon-start" value="addressed"
                                    class="checkmark-crossmark-icon custom-segment-green" mode="ios"
                                    :disabled="item.markedStatusChangeEnabled == false">
                                    <div>
                                        <ion-icon style="font-size: 25px; margin-top:2px"
                                            name="md-checkmark"></ion-icon>
                                    </div>
                                </ion-segment-button>
                                <ion-segment-button
                                    @click="updateAddressNotAddressFromDashboard(beneId, item, 'not_addressed')"
                                    layout="icon-start" value="not_addressed"
                                    class="checkmark-crossmark-icon custom-segment-red" mode="ios"
                                    :disabled="item.markedStatusChangeEnabled == false">
                                    <div>
                                        <ion-icon style="font-size: 25px; margin-top:2px" name="close"></ion-icon>
                                    </div>
                                </ion-segment-button>
                            </ion-segment>
                        </ion-row>

                        <div>
                        </div>
                    </ion-row>
                </ion-grid>
            </ion-card-content>
        </ion-card>
    </ion-col>
</template>

<script>
import { addIcons } from "ionicons";
import { EventBus } from "@/services/Events";
import { more, checkmarkCircle, checkmark } from "ionicons/icons";
import ModalGapsInCare from "@/components/Settings/Patient/ModalGapsInCare";
import { send as httpSend } from "@/services/Api";

addIcons({
    "md-more": more.md,
    "md-checkmark-circle": checkmarkCircle.md,
    "md-checkmark": checkmark.md
});

export default {
    props: {
        item: Object,
        isCarded: Boolean,
        beneId: Number,
        isMarkedStatusEnabled: Boolean,
        canUpdateGapsInCare: Boolean,
        currentPatient: Object,
        classes: { type: String, required: false, default: "" },
    },
    data() {
        return {
            isAddressStatusChanged: false,
            selectedValue: null,
        };
    },
    methods: {
        checkAddressStatus(item) {
            let status;
            if (item.markedStatus === null || item.markedStatus === 'NOT_SPECIFIED') {
                status = 'not_specified';
            } else if (item.markedStatus === 'ADDRESSED') {
                status = 'addressed';
            } else if (item.markedStatus === 'NOT_ADDRESSED') {
                status = 'not_addressed';
            }
            return status;
        },
        async markedAddressNotAddress(benId, item, addressStatus) {
            this.errorMsg = "";
            const method = "post";
            let payload = {};
            const path = document.config.gapsInCare + benId + "/markedStatus/" + item.gapId;

            if (item.markedStatus && item.markedStatus.toLowerCase() === addressStatus) {
                payload = {
                    markedStatus: 'not_specified'
                }
            } else {
                payload = {
                    markedStatus: addressStatus
                }
            }
            return httpSend({ path, method, payload, authToken: true })
                .then(async () => {
                    this.isLoading = false;
                    EventBus.$emit("updateStatus");
                })
                .catch(error => {
                    this.$ionic.toastController
                        .create({
                            header: "Failed to update the status",
                            message: error,
                            duration: 7000,
                            position: "top"
                        })
                        .then(m => m.present());
                });
        },
        updateAddressNotAddressFromDashboard(beneId, item, addressed) {
            this.errorMsg = "";
            const method = "post";
            let newStatus = 'not_specified';
            const path = document.config.gapsInCare + beneId + "/markedStatus/" + item.gapId;

            if (item.markedStatus === 'ADDRESSED' && addressed === 'addressed') {
                newStatus = 'not_specified'
            } else if (item.markedStatus === 'ADDRESSED' && addressed === 'not_addressed') {
                newStatus = 'not_addressed'
            } else if (item.markedStatus === 'NOT_ADDRESSED' && addressed === 'not_addressed') {
                newStatus = 'not_specified'
            } else if (item.markedStatus === 'NOT_ADDRESSED' && addressed === 'addressed') {
                newStatus = 'addressed'
            } else if (item.markedStatus === null || item.markedStatus === 'NOT_SPECIFIED' && addressed === 'addressed') {
                newStatus = 'addressed'
            } else if (item.markedStatus === null || item.markedStatus === 'NOT_SPECIFIED' && addressed === 'not_addressed') {
                newStatus = 'not_addressed'
            }

            const payload = {
                markedStatus: newStatus
            }

            httpSend({ path, method, payload, authToken: true })
                .then(async () => {
                    item.markedStatus = newStatus.toUpperCase();
                })
                .catch(error => {
                    this.$ionic.toastController
                        .create({
                            header: "Failed to update the status",
                            message: error,
                            duration: 7000,
                            position: "top"
                        })
                        .then(m => m.present());
                });
        },
        openModalGaps(beneId, title, item, addressed, canUpdateGapsInCare, currentPatient, isCarded) {
            if (!isCarded) {
                this.$ionic.modalController
                    .create({
                        component: ModalGapsInCare,
                        mode: "ios",
                        cssClass: "prime-modal-xwide",
                        componentProps: {
                            propsData: {
                                beneId,
                                title,
                                item,
                                addressed,
                                canUpdateGapsInCare,
                                currentPatient
                            }
                        },
                        backdropDismiss: false
                    })
                    .then(m => m.present());
            }

        },
        getCdeStatus(cdeStatus) {
            const cdeStatusDictionary = {
                ready_for_entry: { cssClass: "pill-blue gray-pill", text: "Ready for Entry" },
                first_review_needed: { cssClass: "gray-pill review-needed", text: "First Review Needed" },
                second_review_needed: { cssClass: "gray-pill review-needed", text: "Second Review Needed" },
                in_first_review: { cssClass: "gray-pill review-needed", text: "Being Reviewed" },
                in_second_review: { cssClass: "gray-pill review-needed", text: "Being Reviewed" },
                approved: { cssClass: "pill-wrap-green", text: "Approved" },
                rejected: { cssClass: "pill-wrap-urgent", text: "Rejected" }
            };

            return {
                displayName: cdeStatusDictionary[cdeStatus].text,
                displayClass: cdeStatusDictionary[cdeStatus].cssClass,
                displayText: cdeStatusDictionary[cdeStatus].text
            };
        }
    }
};
</script>

<style lang="scss">
#tooltip {
    width: max-content !important;
    max-width: 600px !important;
}

.qc-inner {
    padding: 0 !important;
    display: flex;
    flex-flow: row;
    align-items: start;
    column-gap: 10px;

    .qc-notes {
        // display: none;
    }

    .qc-instructions {
        margin-top: 2px;
    }
}

supporting-facts {
    visibility: visible;

    span {
        font-weight: 600;
        font-size: 11px;

        &:first-child {
            color: var(--ion-color-medium-shade);
            margin-right: 5px;
            max-width: 90px;
        }

        &:last-child {
            color: #000;
        }
    }

    &.overview-sp {
        display: flex;

        span {
            &:first-child {
                flex: 1 0 92px;
            }
        }

    }

    &.modal-sp {
        display: flex;
        flex-flow: column;

        span {
            margin: 0;

            &:first-child {
                margin-bottom: 5px;
            }

            &:last-child {
                color: #000;
            }
        }
    }
}
</style>

<style scoped lang="scss">
#app h6 {
    font-size: 13px;
}

.margin-top-two {
    margin-top: 2px;
}

.prime-channel-tabs-container {
    width: 200px;
}

.title {
    display: block;
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 2px;
}

.add-notaddress-tabs-container {
    width: 60px;
}

.custom-segment-green {
    --background-checked: var(--ion-color-success);
}

.custom-segment-red {
    --background-checked: var(--ion-color-danger);
}

.checkmark-crossmark {
    height: 30px;
}

.checkmark-crossmark-icon {
    height: 26px;
}

.add-notes-btn {
    justify-content: flex-end;
    top: 5px;
    right: 10px;
    height: 20px;
}

.prime-button-width {
    width: 28px;
    height: 28px;
    border-radius: 8px;
    padding-top: 4px;
    padding-right: 0px;
    padding-left: 0px;
}

button.prime-button.button-clear {
    padding-left: 4px;
    padding-right: 4px;
    font-size: 18px;
}

.fact-label {
    white-space: nowrap;
}

.template-span {
    margin-top: 2px;
    display: flex;
}

.template-span-dash {
    margin-left: -10px
}

.parte-card {
    list-style-type: none;
    display: flex;
    margin: 0;
    margin-top: -2px;
    padding: 0;
}

.notes {
    color: var(--ion-color-medium-shade);
    font-weight: normal;
}

.pill {
    min-width: 85px;
    line-height: 80%;
    font-size: 10.3px;
    color: black;
    margin-top: 0;
}

.pill-blue {
    color: white;
    line-height: 80%;
    padding-right: 10px;
}

.pill-sm {
    margin-top: 1px;
}

.gray-pill {
    min-width: 135px;
}

h5 {
    margin-top: 30px;
}

.maxed-col {
    max-width: 70%;
}

.flatten-card ion-card-content,
.flatten-card {
    all: revert;
    cursor: pointer;
}

.flatten-card ion-grid {
    margin-bottom: 10px;
    border-bottom: 1px solid #e2e2e2;
    padding-bottom: 12px;
}

.flatten-card ion-col {
    padding: 0 0 0 0px !important;
}

.flatten-card .maxed-col {
    max-width: 85%;
}

.review-needed {
    border: 2px solid rgb(255, 164, 61);
}

.pill-wrap-green {
    background-color: #70c01c;
    min-width: 100px;
    color: #fff;
}

.pill-wrap-urgent {
    background-color: #e02020;
    min-width: 100px;
    color: #fff;
}

.editing-content {
    opacity: 1;
    transition: all 0.2s ease-in-out;

    &.disabled {
        opacity: 0.6;
        pointer-events: none;
    }

}
</style>