<template>
  <div class="prime-modal">
    <ion-header>
      <ion-toolbar>
        <h6 slot="start">Clinical Data Entry - Confirm Clear</h6>
        <ion-button slot="end" mode="ios" @click="closeModal()" expand="full" color="medium" fill="clear"><ion-icon name="md-close"></ion-icon></ion-button>
      </ion-toolbar>
    </ion-header>
    <ion-content class="prime-modal-content">
      <p>Are you sure you would like to clear all entered clinical data? This action cannot be undone.</p>
    </ion-content>
    <ion-footer>
      <ion-grid>
        <ion-row>
          <ion-col class="pad-right-ten full-height overflow-y-scroll">
            <button @click="closeModal" class="prime-button button-block button-secondary">
              <span>Cancel</span>
            </button>
          </ion-col>
          <ion-col>
            <button class="prime-button button-block" @click="confirmClearData">
              Confirm
            </button>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-footer>
  </div>
</template>

<script>
import { send as httpSend } from "@/services/Api";
import { EventBus } from "@/services/Events";
export default {
  name: "ModalUpdateGapEntry",
  props: {
    requestId: {
      type: Number,
      required: true
    }
  },
  methods: {
    closeModal() {
      //this.$ionic.modalController.dismiss();
      this.$ionic.popoverController.dismiss();
    },
    async confirmClearData() {
      const method = "delete";
      const path = document.config.deleteCdeRequest + this.requestId;
      try {
        const response = await httpSend({ path, method, authToken: true });
        if (response.status == 204) {
          this.$ionic.toastController
            .create({
              header: "Success",
              message: "Data cleared",
              duration: 4000,
              color: "primary",
              mode: "ios"
            })
            .then(m => m.present());
          //EventBus.$emit("updateQualityConsideration");
          EventBus.$emit("clearingData");
          this.closeModal();
        } else {
          this.$ionic.toastController
            .create({
              header: "Failed to clear data",
              message: "error",
              duration: 7000,
              position: "top"
            })
            .then(m => m.present());
          this.closeModal();
        }
      } catch (error) {
        this.$ionic.toastController
          .create({
            header: "Failed to clear data",
            message: error,
            duration: 7000,
            position: "top"
          })
          .then(m => m.present());
        this.closeModal();
        khanSolo.log(error);
      }
    }
  }
};
</script>
<style scoped>
ion-footer {
  padding: 10px;
}

.prime-modal .prime-modal-content {
  padding: 0 20px;
  border-bottom: none;
}

h6,
button {
  text-transform: capitalize;
}
</style>
