const riskClassMap = new Map();

riskClassMap.set([1, 2], "low");
riskClassMap.set([3, 4], "moderate");
riskClassMap.set([5, 6], "high");
riskClassMap.set([7], "need-confirmed");

export const findClass = num => {
  let val = "unknown";
  for (let [key, value] of riskClassMap) {
    if (key.includes(num)) {
      val = value;
      break;
    }
  }
  return `${val}`;
};
