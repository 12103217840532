<template>
  <div>
    <div v-if="isLoading" class="center-center fade-in loading-overlay">
      <ion-spinner class="center-center spinner-large" name="lines" color="dark"></ion-spinner>
    </div>
    <ion-row v-if="diagnosisMeasureReports && diagnosisMeasureReports.length == 0">
      <ion-col class="display-flex valign center-center no-programs">
        <div class="text-muted">
          No Diagnosis Considerations currently available.
        </div>
      </ion-col>
    </ion-row>
    <div v-else>
      <ion-row class="w-full">
        <diagnosis-consideration-page
          :measure_reports.prop="diagnosisMeasureReports"
          :patient_id="beneficiaryId"
          :practitioner_id.prop="practitionerId"
          :valueset_loader_function.prop="valueSetLoaderFunction"
          :documentation_assistant_enabled="true"
          :can_edit.prop="getAttestationEditable"
          :document_references.prop="diagnosisDocumentReferences"
          :up_to_date_enabled.prop="false"
          :show_subcategory_title.prop="showSubcategoryTitle"
          @document_reference_change="onDocumentReferenceChange"
          @launchNotesModal="onModalInit"
        ></diagnosis-consideration-page>
      </ion-row>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import { engageFhirApi } from "../../../services/EngageFhirApi";
import ModalDxHistoryNotes from "@/components/Settings/Patient/ModalDxHistoryNotes";
import { getCurrentProfessional } from "@/services/Api/getCurrentProfessional";

export default {
  name: "DiagnosisConsiderationWrapper",

  props: {
    beneficiaryId: {
      required: true,
      type: Number
    },
    showSubcategoryTitle: {
      required: false,
      type: Boolean,
      default: true
    }
  },

  data() {
    return {
      isSavingCounter: 0,
      isLoading: false,
      practitionerId: null
    };
  },
  watch: {
    isSavingCounter(newVal) {
      this.$emit("doc-ref-saving-counter-updated", newVal);
    },
    isLoading(newVal) {
      this.$emit("doc-ref-loading", newVal);
    }
  },

  async mounted() {
    this.practitionerId = await this.getPractitionerId();
    await this.getDiagnosisConsiderations();
  },

  computed: {
    ...mapGetters({
      getAttestationEditable: "diagnosisConsiderations/getAttestationEditable",
      diagnosisMeasureReports: "diagnosisConsiderations/getDiagnosisMeasureReports",
      diagnosisDocumentReferences: "diagnosisConsiderations/getDiagnosisDocumentReferences"
    })
  },

  methods: {
    ...mapActions({
      getDiagnosisConsiderationData: "diagnosisConsiderations/fetchDCData",
      updateDocumentReference: "diagnosisConsiderations/updateDocumentReference"
    }),
    /**
     * Open Modal
     */
    onModalInit(ev) {
      this.$ionic.modalController
        .create({
          component: ModalDxHistoryNotes,
          mode: "ios",
          cssClass: "prime-modal-med",
          componentProps: {
            propsData: {
              title: "Consideration Notes and History",
              docDetails: ev.detail,
              practitionerId: this.practitionerId
            }
          },
          backdropDismiss: false
        })
        .then(m => m.present());
    },

    async onDocumentReferenceChange(e) {
      try {
        this.isSavingCounter++;
        const updatedDocRef = await engageFhirApi.post(`/DocumentReference`, e.detail);
        this.updateDocumentReference(updatedDocRef);
        this.isSavingCounter--;
      } catch (error) {
        this.isSavingCounter--;
        khanSolo.error("Error saving DocumentReference");
        khanSolo.error(error);
        const toast = await this.$ionic.toastController.create({
          header: "Error!",
          message: "There was a problem saving your status. Please try again later.",
          duration: 4000,
          position: "top"
        });
        toast.present();
      }
    },

    async getDiagnosisConsiderations() {
      try {
        this.isLoading = true;
        await this.getDiagnosisConsiderationData(this.beneficiaryId);
        this.isLoading = false;
      } catch (error) {
        khanSolo.error("Error fetching Diagnosis Considerations");
        khanSolo(error);
        const toast = await this.$ionic.toastController.create({
          header: "Failed to get documentation",
          message: "There was a problem getting your documentation.",
          duration: 7000,
          position: "top"
        });
        toast.present();
        this.isLoading = false;
      }
    },

    /** Get user's practitioner ID from auth token */
    async getPractitionerId() {
      const data = await getCurrentProfessional();
      return data.professional.id;
    },
    async valueSetLoaderFunction(measureId) {
      try {
        return await engageFhirApi.get(`/ValueSet/measure/${measureId}`);
      } catch (error) {
        if ([error.status, error.statusCode].includes(404)) {
          khanSolo.log(`No ValueSet found for measure ${measureId}`);
        } else {
          khanSolo.error(`Error fetching ValueSet for measure ${measureId}`);
          khanSolo.error(error);
        }
        return [];
      }
    }
  }
};
</script>
<style>
diagnosis-consideration-page {
  width: 100%;
}
.no-programs {
  height: 100px;
}
</style>
