<template>
  <ion-list mode="ios">
    <ion-radio-group>
      <ion-item>
        <ion-label>Send as Text Message</ion-label>
        <ion-radio :checked="smsChannel" slot="end" value="sms" @click="setMsgChannel($event, false)"></ion-radio>
      </ion-item>
      <ion-item v-if="$can(I.ACCESS_DEBUGGER)">
        <ion-label>Send as SMS with Debugger</ion-label>
        <ion-radio :checked="dbuggaChannel" slot="end" value="smsDbugga" @click="setMsgChannel($event, true)"></ion-radio>
      </ion-item>
      <ion-item>
        <ion-label>Send as Secure Chat</ion-label>
        <ion-radio :checked="zeusChannel" slot="end" value="zeus" @click="setMsgChannel($event, false)"></ion-radio>
      </ion-item>
    </ion-radio-group>
  </ion-list>
</template>

<script>
import { EventBus } from "@/services/Events.js";
import store from "@/store";

export default {
  name: "PopoverSecureMsgSelector",
  data() {
    return {
      msgChannel: this.isDbugga && this.dbuggaMode ? "smsDbugga" : "sms"
    };
  },
  methods: {
    setMsgChannel(event, dbugga) {
      store.commit("dbugga/resetDbuggaMode", this.isDbugga && dbugga);
      let returnVal = event.target.value;
      if (returnVal == "smsDbugga") {
        returnVal = "sms";
      }

      EventBus.$emit("messageChannel", returnVal);
      this.closePopover();
    },
    closePopover() {
      this.$ionic.popoverController.dismiss();
    }
  },
  computed: {
    smsChannel() {
      return this.msgChannel == "sms" && this.isDbugga && !this.dbuggaMode;
    },
    dbuggaChannel() {
      return this.msgChannel == "smsDbugga" || (this.msgChannel == "sms" && this.isDbugga && this.dbuggaMode);
    },
    zeusChannel() {
      return this.msgChannel == "zeus";
    },
    dbuggaMode() {
      return store.getters["dbugga/dbuggaMode"];
    }
  }
};
</script>

<style scoped>
ion-list.list-ios {
  margin-bottom: 0;
}
ion-item {
  padding: 0 5px;
}
ion-item:hover {
  background: var(--ion-color-light);
}
ion-label {
  font-weight: 500;
}
</style>
