<template>
  <div class="prime-modal">
    <ion-header>
      <ion-toolbar>
        <h5 slot="start">Risk Model Details</h5>
        <ion-button slot="end" mode="ios" @click="closeModal()" expand="full" color="medium" fill="clear"><ion-icon name="md-close"></ion-icon></ion-button>
      </ion-toolbar>
    </ion-header>
    <ion-content class="prime-modal-content">
      <h6>{{ riskObj.model }}</h6>
      <p>{{ riskObj.description }}</p>
      <div class="pill text-bold text-center text-capitalize risk-pill" :class="getRiskClass(riskObj.predictionLevel)">
        <div class="main-val">{{ riskObj.prediction ? riskObj.prediction : "Unknown" }}</div>
        <div v-if="riskObj.trend === 'Rising'" class="trend pill"><IconRisingTrend />{{ riskObj.trend }}</div>
      </div>
      <div class="margin-top-ten">
        <span class="text-small text-muted">Date prediction generated:</span>
        <span class="text-small text-bold">
          {{ riskObj.lastUpdated | moment("MMM DD, YYYY") }}
        </span>
      </div>
      <div v-if="riskObj.drivers">
        <h6>Risk Drivers</h6>
        <ol>
          <li v-for="(driver, index) in riskObj.drivers" :key="index">
            <p class="text-bold">{{ driver }}</p>
          </li>
        </ol>
      </div>
    </ion-content>
    <ion-footer>
      <ion-grid>
        <ion-row>
          <ion-col>
            <button @click="closeModal()" class="prime-button button-block">
              <span>Close</span>
            </button>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-footer>
  </div>
</template>

<script>
import IconRisingTrend from "@/components/Global/Icons/IconRisingTrend";
import { findClass } from "./RiskMap";
export default {
  name: "ModalSdoh",
  props: {
    riskObj: Object,
    beneficiaryId: Number
  },
  components: {
    IconRisingTrend
  },
  data() {
    return {
      contentType: "Social Determinants of Health Details",
      hasNeed: true
    };
  },
  methods: {
    getRiskClass(predictionLevel) {
      return findClass(predictionLevel);
    },
    closeModal() {
      this.$ionic.modalController.dismiss();
    }
  }
};
</script>

<style scoped>
.prime-modal-content {
  border: none;
  padding: 0 20px 5px;
}

ion-footer {
  padding: 20px;
}

.marking-div {
  height: 60%;
}

.pill {
  display: flex;
  min-height: 2.3rem;
}

.main-val {
  flex: 5;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6px;
}

.trend.pill {
  flex: 1;
  padding: 5px 9px;
  text-align: center;
  font-size: smaller;
  font-weight: bold;
  text-transform: capitalize;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  align-items: center;
}

.trend svg {
  width: 20px;
  height: 20px;
  vertical-align: middle;
  margin-right: 4px;
}

.risk-pill.high {
  background: rgb(230, 30, 37);
  color: var(--ion-color-white);
}
.risk-pill.moderate {
  background: rgb(253, 172, 42);
  color: var(--ion-color-black);
}

.risk-pill.low {
  background: rgb(91, 184, 45);
  color: var(--ion-color-white);
}

.non-style-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
</style>
