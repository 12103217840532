<template>
  <div class="prime-modal">
    <ion-header>
      <ion-toolbar>
        <IconPersonAdd class="icon-small" />
        <h5 slot="start">Alerts</h5>
        <ion-button slot="end" mode="ios" @click="closeModal(null)" expand="full" color="primary" fill="clear"><ion-icon name="md-close"></ion-icon></ion-button>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      Alerts go here
    </ion-content>
  </div>
</template>

<script>
// import { send as httpSend } from "@/services/Api";

export default {
  name: "ModalAlerts",
  components: {},
  data() {
    return {};
  },
  methods: {
    closeModal() {
      this.$ionic.modalController.dismiss();
    }
  },
  async mounted() {
    // const path = document.config.getCareTeamByOrg;
    // const method = "get";
    // const payload = null;
    // try {
    //   this.careTeamByOrg = await httpSend({
    //     path,
    //     method,
    //     payload,
    //     authToken: true
    //   });
    // } catch (error) {
    //   khanSolo.log(error);
    // }
  }
};
</script>
<style scoped></style>
