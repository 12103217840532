<template>
  <div class="prime-modal">
    <ion-header class="bottom-divider-v2">
      <ion-toolbar>
        <h5 slot="start">{{ title }}</h5>
        <ion-button
          slot="end"
          mode="ios"
          @click="closeModal()"
          expand="full"
          color="medium"
          fill="clear"
          ><ion-icon name="md-close"></ion-icon
        ></ion-button>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <ion-grid class="full-height">
        <ion-row class="full-height">
          <ion-col class="tabs-divider notes-section" style="margin: 0;">
            <div
              id="notes-wrapper"
              class="notes-wrapper margin-top-twenty margin-bottom-twenty pad-left-twenty pad-right-twenty"
            >
              <ion-spinner v-if="isLoading" name="lines" class="absolute-center"></ion-spinner>
              <ul id="notes" class="notes" :class="{ disable: isLoading }">
                <li
                  v-for="(note, index) in notesWithHistory.content"
                  :key="index"
                  class="pad-bottom-fifteen pad-top-fifteen bottom-divider-v2"
                >
                  <div
                    class="generic-card card-like-note-flat doc-assist-card display-flex flex-col centerItems margin-top-five full-height"
                  >
                    <ion-row class="card-notes-row doc-card display-flex valign margin-top-fifteen">
                      <div v-html="getDecodedMessage(note.attachment.data)"></div>
                    </ion-row>
                  </div>
                  <div class="margin-top-five">
                    <span class="date-label text-bold">
                      {{ note.attachment.creation | moment("M/DD/YYYY - h:mmA") }} -
                    </span>
                    <span class="by text-bold">{{ getAuthorDisplay(note.extension) }}</span>
                  </div>
                </li>
              </ul>

              <div class="note-input pad-bottom-ten">
                <ion-grid>
                  <ion-row class="ion-align-items-center">
                    <ion-col class="vgt-wrap">
                      <textarea-autosize
                        placeholder="Leave a note or comment.."
                        class="form-control prime-message-input"
                        rows="1"
                        v-model="newNote"
                        :min-height="53"
                        :max-height="350"
                        @keydown.native.enter.exact.prevent
                        @keyup.native.enter.exact="postNewNote()"
                        @keydown.native.enter.shift.exact="newline"
                      />
                      <button
                        type="button"
                        class="prime-button button-secondary send-message-button add-notes-btn"
                        :class="{ disable: isLoading }"
                        @click="postNewNote()"
                      >
                        <div>
                          <ion-icon
                            name="arrow-up"
                            size="large"
                            :color="'primary'"
                            mode="md"
                          ></ion-icon>
                        </div>
                        <ion-spinner name="dots" duration="1200" color="white"></ion-spinner>
                      </button>
                    </ion-col>
                  </ion-row>
                </ion-grid>
              </div>
            </div>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-content>
    <ion-footer>
      <button class="prime-button button-block button-secondary" @click="closeModal">
        Close
      </button>
    </ion-footer>
  </div>
</template>

<script>
import "vue-select/dist/vue-select.css";
import { addIcons } from "ionicons";
import { closeCircle, more, radioButtonOff } from "ionicons/icons";
import { engageFhirApi } from "../../../services/EngageFhirApi";
import "vue-select/dist/vue-select.css";
import store from "@/store";

addIcons({
  "md-more": more.md,
  "md-radio-button-off": radioButtonOff.md,
  "md-close-circle": closeCircle.md
});

export default {
  name: "ModalDiagnosisConsideration",
  props: {
    title: String,
    docDetails: Object,
    practitionerId: Number
  },
  data() {
    return {
      /** Document references for this measure */
      notesWithHistory: [],
      /** Modal text input value */
      newNote: "",
      isLoading: false,
      includeAudits: true
    };
  },

  async mounted() {
    this.isLoading = true;
    await this.getNotesWithHistory();
    this.updateScrollBarLocation();
  },

  computed: {
    diagnosisDocumentReferences() {
      return store.getters["diagnosisConsiderations/getDiagnosisDocumentReferences"];
    }
  },

  methods: {
    /** Update a document reference in the store */
    updateDocumentReference(updatedDocRef) {
      return store.dispatch("diagnosisConsiderations/updateDocumentReference", updatedDocRef);
    },

    /**
     * Get the notes and history for this measure
     * @param doc emitted document details, containing the measureId & year.
     * @param references all of the beneficiary's document references
     * @returns Document reference for this measure, with attachments sorted by creation date
     */
    findMeasDocRef(doc, references) {
      if (!references) {
        return null;
      }

      const measureNotes = references.find(x => {
        return (
          x.identifier.some(y => {
            return (
              y.system === "https://lumeris.com/fhir/CodeSystem/measure-report-identifier" &&
              y.value === doc.measureId
            );
          }) &&
          x.identifier.some(y => {
            return (
              y.system === "https://lumeris.com/fhir/CodeSystem/measure-year" &&
              y.value === doc.measureYear.toString()
            );
          })
        );
      });

      if (!measureNotes) return [];

      measureNotes.content = measureNotes.content.sort((a, b) => {
        return new Date(a.attachment.creation) - new Date(b.attachment.creation);
      });

      return measureNotes;
    },

    getAuthorDisplay(extension) {
      if (!extension) {
        return false;
      }
      const authorExtension = extension.find(x => {
        return x.hasOwnProperty("valueReference");
      });
      return authorExtension !== undefined ? authorExtension.valueReference.display : "Unknown";
    },

    getDecodedMessage(data) {
      try {
        return window.atob(data);
      } catch (err) {
        khanSolo(err);
      }
    },

    /**
     * Adds new note
     * @POST
     */
    async postNewNote() {
      try {
        const noteText = this.newNote;

        // Remove any text from textarea
        this.newNote = "";
        this.isLoading = true;

        // Notes need posted without audit logs and history to prevent duplication
        const docRefWithoutHistory = this.findMeasDocRef(
          this.docDetails,
          this.diagnosisDocumentReferences
        );

        const newNote = {
          attachment: {
            contentType: "text/plain",
            data: window.btoa(noteText),
            id: null
          },
          extension: [
            {
              url:
                "https://lumeris.com/fhir/CodeSystem/DiagnosisConsiderationNotesAndHistoryCategory",
              valueString: "Freeform Notes"
            },
            {
              url: "https://lumeris.com/fhir/CodeSystem/DocumentationAssistant/Practitioner",
              valueReference: {
                reference: `Practitioner/${this.practitionerId}`,
                type: "Practitioner"
              }
            }
          ]
        };
        docRefWithoutHistory.content.push(newNote);

        const updatedDocRef = await engageFhirApi.post(`/DocumentReference`, docRefWithoutHistory);

        this.updateDocumentReference(updatedDocRef);

        await this.getNotesWithHistory();

        // Reinitialize scroll to go to bottom
        this.updateScrollBarLocation();

        this.isLoading = false;
      } catch (error) {
        khanSolo.error(error);
        this.isLoading = false;
        const toast = await this.$ionic.toastController.create({
          header: "Error!",
          message: "There was a problem saving your note. Please try again later.",
          duration: 4000,
          position: "top"
        });
        toast.present();
      }
    },

    /**
     * Retrieving notes
     * @GET
     */
    async getNotesWithHistory() {
      try {
        this.notesWithHistory = [];
        const beneDocRefsWithHistory = await engageFhirApi.get(
          `/DocumentReference/beneficiary/${this.docDetails.patientId}${
            this.includeAudits ? "?includeNotesHistory=true" : ""
          }`
        );
        this.notesWithHistory = this.findMeasDocRef(this.docDetails, beneDocRefsWithHistory) || [];
        this.isLoading = false;
      } catch (error) {
        khanSolo.error("Error getting notes with history");
        khanSolo.error(error);
        this.isLoading = false;
      }
    },

    /**
     * Updates notes section scroll position to bottom
     */
    updateScrollBarLocation() {
      const notesSection = document.querySelector("#notes-wrapper");

      setTimeout(() => {
        notesSection.scroll({ top: notesSection.scrollHeight, behavior: "smooth" });
      }, 300);
    },

    /**
     * Closes modal
     */
    closeModal() {
      this.$ionic.modalController.dismiss();
    }
  }
};
</script>

<style lang="scss">
.send-message-button {
  &.disable {
    pointer-events: none;
  }
}
.diagnosis-selection {
  .vs__dropdown-toggle {
    padding: 5px 0;
  }
  .vs__selected {
    text-wrap: nowrap;
    margin-top: 0;
  }

  .vs__search {
    display: none;
  }
}

.doc-assist-card {
  h1,
  h2,
  h3,
  h4,
  h5 {
    line-height: 1;
    margin: 0 0 10px 0;
    font-size: 12px;
  }

  p {
    margin: 0 0 10px 0;
    font-size: 12px;
  }
}
</style>

<style scoped lang="scss">
ion-footer {
  bottom: 0;
  position: absolute;
  padding: 10px 20px;
}
ion-icon {
  margin-right: 10px;
  width: 25px;
  height: 25px;

  .icon-inner {
    background-color: var(--ion-color-light);
    svg {
      fill: var(--ion-color-light);
    }
  }
}

.prime-message-input {
  padding: 15px 15px 7px;
}

.meta {
  color: var(--ion-color-medium-shade);
  font-size: smaller;
}

.by {
  font-size: smaller;
}

.date-label {
  color: var(--ion-color-medium-shade);
  font-size: 11px;
  margin-right: 7px;
  margin-left: 4px;
}

.date {
  font-size: 11px;
}

.notes-wrapper {
  overflow-y: scroll;
  height: calc(100% - 150px);

  .notes {
    list-style-type: none;
    margin: 0;
    padding: 0;
    opacity: 1;

    &.disable {
      opacity: 0.5;
    }

    li {
      margin-bottom: 0px;
      border-bottom: 1px solid var(--ion-color-light-shade);

      &:last-child {
        border-bottom: none;
      }
    }
  }
}

.add-notes-btn {
  position: absolute;
  top: 11px;
  right: 10px;
  height: 31px;
}

.note-input {
  position: absolute;
  bottom: 60px;
  width: 92%;
}

ion-content {
  --padding-bottom: 0;
  --padding-end: 0;
  --padding-start: 0;
}

.notes-section {
  background: var(--ion-background-secondary-color);
  height: 100%;
}

.link-copy {
  text-decoration: none;
  margin-left: 8px;
  font-size: 12px;
  max-width: 184px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.file-history {
  max-width: 368px;
}

.generic-card {
  margin: 5px 5px 5px 0;
  padding: 5px 15px;
  border-radius: 5px;
  width: 100%;
}

.file-card {
  height: 45px;

  figure {
    margin: 1em 0;

    svg {
      fill: var(--ion-color-primary);
    }
  }
}

.card-like {
  box-shadow: 0 4px 4px -2px rgba(46, 61, 73, 0.1);
  border: 1px solid #cccccc40;
  background: var(--ion-color-light);
}

.file-card-notes {
  height: 75px;

  figure {
    margin: 0;

    svg {
      fill: var(--ion-color-primary);
    }
  }
}

.card-like-notes {
  box-shadow: 0 4px 4px -2px rgba(46, 61, 73, 0.1);
  border: 1px solid #cccccc40;
  background: var(--ion-color-white);

  .card-notes-row {
    margin: 3px 0;
  }
}

.card-like-note-flat {
  border: 1px solid #cccccc40;
  background: var(--ion-color-white);
  width: 90%;

  .card-notes-row {
    margin: 3px 0;
  }

  .doc-card {
    margin-top: 15px;
  }
}

.drag-and-drop {
  border: 2px dashed #dadada;
  border-radius: 10px;
}

.drag-and-drop.dropping {
  border: 2px dashed var(--ion-color-primary);
  background-color: #f0f0f0;
}

input.form-control {
  height: 100% !important;
}
.editing-content {
  opacity: 1;
  transition: all 0.2s ease-in-out;

  &.disabled {
    opacity: 0.6;
    pointer-events: none;
  }

  .content-scroll {
    overflow-y: scroll;
    height: 580px;
    padding: 15px 20px 20px 0;
  }
}
.upload-wrapper {
  opacity: 1;
  transition: all 0.2s ease-in-out;

  &.disabled {
    opacity: 0.4;
    pointer-events: none;
  }
}
.prime-v-date-picker-container {
  height: 100%;

  ion-icon {
    top: 50%;
    transform: translateY(-50%);
  }

  .prime-v-date-picker {
    height: 100%;
    visibility: visible;

    input {
      padding: 13px 15px 12px !important;
      height: 100%;
    }
  }
}
.prime-file-upload {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}
.prime-file-upload + label {
  font-weight: 700;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
  white-space: normal;
}

.prime-file-upload + label {
  cursor: pointer;
  color: var(--ion-color-primary);
}
.prime-file-upload + label svg {
  fill: var(--ion-color-primary);
}
.prime-file-upload + label figure {
  margin: 1em 0;
}
.prime-file-upload + label h5 {
  font-size: 12px;
  margin: 0;
}

.prime-modal-patient-results {
  padding: 20px 0 10px;
}
.prime-modal ion-content.prime-patient-results-container {
  --background: var(--ion-modal-secondary-background);
}
.prime-modal
  ion-content.prime-patient-results-container
  .prime-modal-patient-results-notifications {
  margin: 0 20px;
}
.prime-modal-patient-results small {
  font-size: 12px;
  font-weight: 500;
  text-align: center;
  display: block;
  color: var(--ion-color-medium-shade);
  margin: 20px 0 10px;
}
.prime-modal-content.patient-selected .prime-patient-card {
  margin-bottom: 25px !important;
}
.prime-modal-content.patient-selected {
  overflow-y: auto;
}
.prime-modal-content.patient-selected .form-sub-label {
  font-size: 12px;
  font-weight: 500;
  margin-top: -8px;
  margin-bottom: 5px;
  color: var(--ion-color-medium-shade);
}
.dropdown-button {
  width: 60px;
}
.button-group {
  display: flex;
}

.ion-textarea-wrapper {
  width: 100%;
  min-height: 150px;
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 10px;

  .ion-textarea {
    border-radius: var(--border-radius);
    margin-left: 0;
    margin-right: 0;
    margin-top: 0;
    margin-bottom: 0;
    padding-left: var(--padding-start);
    padding-right: var(--padding-end);
    padding-top: var(--padding-top);
    padding-bottom: var(--padding-bottom);
    display: block;
    width: 100%;
    max-width: 100%;
    max-height: 100%;
    border: 0;
    outline: none;
    background: transparent;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    resize: none;
  }
}

.custom-radio-group {
  display: flex;
  flex-flow: column;

  ion-item {
    --border-color: white;
    --background-hover: none;
  }

  ion-radio {
    left: 0;
    position: absolute;
    margin: 0 10px 0 0;
  }

  span {
    margin-left: 15px;
  }
}

.add-visit-note-select {
  width: 100%;
  text-align: left;
  background: #ffffff;
  border: 2px solid var(--ion-color-light-shade);
  border-radius: 8px;
  padding: 8px 15px 7px;
  font-size: 14px;
  font-family: var(--ion-font-family);
  font-weight: 500;
  color: var(--ion-text-color);
  line-height: 1.5;
  -webkit-transition: border 0.2s ease-in-out;
  transition: border 0.2s ease-in-out;
  padding: 10px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: url(../../../assets/arrow-dropdown.svg) no-repeat right #fff;
  background-size: 15px;
  background-position-x: 405px;
}

.pill-attestiation {
  display: inline-block;
  width: 100%;
  border-radius: 100px;
  padding: 4px 10px;
  background-color: #e6e6e6;
  color: #000;
}
</style>
