<template>
  <div class="prime-modal">
    <ion-header>
      <ion-toolbar>
        <h5 slot="start">{{ contentType }}</h5>
        <ion-button slot="end" mode="ios" @click="closeModal()" expand="full" color="medium" fill="clear"><ion-icon name="md-close"></ion-icon></ion-button>
      </ion-toolbar>
    </ion-header>
    <ion-content v-if="contentType === 'Social Determinants of Health Details'" class="prime-modal-content">
      <h6>{{ sdohObj.model }}</h6>
      <p>{{ sdohObj.description }}</p>

      <div>
        <span class="text-small text-muted">Status:</span>
        <span class="text-small text-bold">
          {{ sdohObj.prediction }}
        </span>
      </div>

      <div class="display-flex margin-top-twenty pad-bottom-ten bottom-divider-thin">
        <div class="flex-4d">
          <div class="text-bold">Predicted Need:</div>
          <div>
            <span class="text-small text-muted">Date prediction generated:</span>
            <span class="text-small text-bold">
              {{ sdohObj.lastUpdated | moment("MMM DD, YYYY") }}
            </span>
          </div>
        </div>
        <div class="pill text-bold text-center text-capitalize flex-1d" :class="getStatusClass(sdohObj.predictionLevel)">
          {{ sdohObj.prediction ? sdohObj.prediction : "Unknown" }}
        </div>
      </div>
      <div v-if="sdohObj.assessments && hasNewAssessment()" class="display-flex margin-top-twenty pad-bottom-ten bottom-divider-thin">
        <div class="flex-4d">
          <div class="text-bold">Last Assessment:</div>
          <div>
            <span class="text-small text-muted">Last Assessed:</span>
            <span class="text-small text-bold">
              {{ sdohObj.assessments[0].createdAt | moment("MMM DD, YYYY") }}
            </span>
          </div>
        </div>
        <div class="pill text-bold text-center text-capitalize flex-1d" :class="sdohObj.assessments[0].hasNeed ? 'need-confirmed' : 'no-need'">
          {{ sdohObj.assessments[0].hasNeed ? "Need Confirmed" : "No Need" }}
        </div>
      </div>
      <div v-if="sdohObj.assessments">
        <h6>Assessment History</h6>
        <ul class="non-style-list">
          <li v-for="(assessment, index) in sdohObj.assessments" :key="index">
            <span class="text-bold">Assessment entered</span>
            <div class="pill text-bold text-center text-capitalize inline-pill" :class="assessment.hasNeed ? 'need-confirmed' : 'no-need'">
              {{ assessment.hasNeed ? "Need Confirmed" : "No Need" }}
            </div>
            <p class="text-bold">"{{ assessment.note }}"</p>
            <div>
              <span class="text-small text-muted">{{ assessment.createdAt | moment("M/DD/YYYY - h:mmA") }} - </span>
              <span class="text-small text-bold">
                {{ assessment.professionalFirstName + " " + assessment.professionalLastName }}
              </span>
            </div>
          </li>
        </ul>
      </div>
    </ion-content>
    <ion-content v-if="contentType === 'Enter Assessment'" class="prime-modal-content">
      <h6>Assess need for {{ sdohObj.model }}:</h6>
      <div class="toggle-button">
        <div class="pill display-inline-block" :class="hasNeed ? 'selected' : ''" @click="hasNeed = !hasNeed">Patient Has Need</div>
        <div class="pill display-inline-block" :class="!hasNeed ? 'selected' : ''" @click="hasNeed = !hasNeed">No Need</div>
      </div>

      <p>Please provide a note explaining your assessment:</p>
      <div class="marking-div">
        <div v-show="errorMsg" class="notifications-well danger-well">
          {{ errorMsg }}
        </div>
        <textarea v-model="newNote" placeholder="Type note here..." class="form-control prime-message-input assessment-note"></textarea>
      </div>
    </ion-content>
    <ion-footer v-if="contentType !== 'Enter Assessment'">
      <ion-grid>
        <ion-row>
          <ion-col v-if="$can(I.EDIT_SDOH)" class="pad-right-twenty full-height overflow-y-scroll">
            <button
              @click="
                errorMsg = '';
                prevContentType = contentType;
                contentType = 'Enter Assessment';
              "
              class="prime-button button-block button-secondary"
            >
              <span>Enter Assessment</span>
            </button>
          </ion-col>
          <ion-col>
            <button @click="closeModal()" class="prime-button button-block">
              <span>Close</span>
            </button>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-footer>
    <ion-footer v-else>
      <ion-grid>
        <ion-row>
          <ion-col class="pad-right-twenty full-height overflow-y-scroll">
            <button
              @click="
                contentType = prevContentType;
                errorMsg = '';
              "
              class="prime-button button-block button-secondary"
            >
              <span>Cancel</span>
            </button>
          </ion-col>
          <ion-col>
            <button @click="saveAssessment" class="prime-button button-block ">
              <span>Save Assessment</span>
            </button>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-footer>
  </div>
</template>

<script>
import { send as httpSend } from "@/services/Api";
import { findClass } from "./RiskMap";
import { EventBus } from "@/services/Events";
export default {
  name: "ModalSdoh",
  props: {
    sdohObj: Object,
    beneficiaryId: Number
  },
  data() {
    return {
      contentType: "Social Determinants of Health Details",
      hasNeed: true,
      newNote: ""
    };
  },
  methods: {
    getStatusClass(predictionLevel = 0) {
      return findClass(predictionLevel);
    },
    closeModal() {
      this.$ionic.modalController.dismiss();
    },
    hasNewAssessment() {
      return new Date(this.sdohObj.assessments[0].createdAt).getTime() > new Date(this.sdohObj.lastUpdated).getTime();
    },
    saveAssessment() {
      const method = "post";
      const path = document.config.sdoh;
      const payload = {
        beneficiaryId: this.beneficiaryId,
        model: this.sdohObj.model,
        note: this.newNote,
        hasNeed: this.hasNeed
      };

      httpSend({ path, method, payload, authToken: true })
        .then(() => {
          EventBus.$emit("updatedSdoh");
          this.closeModal();
        })
        .catch(error => {
          khanSolo.log(error);
        });
    }
  }
};
</script>

<style scoped>
.prime-modal-content {
  border: none;
  padding: 0 20px 5px;
}

ion-footer {
  padding: 20px;
}

.assessment-note {
  height: 100%;
}

.notifications-well {
  width: 100%;
}
.marking-div {
  height: 60%;
}

.pill {
  background: var(--color-secondary-button);
  padding: 0.7rem;
}

.inline-pill {
  width: 40%;
  margin-left: 1rem;
  padding: 2px;
}

.pill:first-letter {
  text-transform: capitalize;
}

.urgent-pill {
  background-color: #e02020;
  color: #fff;
}

.high {
  border: 2px solid rgb(230, 30, 37);
  background: rgb(252, 204, 204);
  color: black;
}

.moderate {
  border: 2px solid rgb(253, 172, 42);
  background: rgb(255, 238, 200);
  color: black;
}

.low {
  border: 2px solid rgb(91, 184, 45);
  background: rgb(220, 240, 205);
  color: black;
}

.no-need {
  color: black;
}

.need-confirmed {
  border: 2px solid rgb(230, 30, 37);
  background: rgb(230, 30, 37);
  color: var(--ion-color-white);
}

.toggle-button {
  border-radius: 100px;
  background: var(--color-secondary-button);
  height: 2.5rem;
}

.toggle-button div {
  text-align: center;
  width: 50%;
  height: 100%;
  padding: 8px;
  cursor: pointer;
  color: var(--ion-color-primary);
  font-weight: bold;
  text-transform: capitalize;
}

.toggle-button div.selected {
  background-color: var(--ion-color-primary);
  color: var(--ion-color-white);
  font-weight: bold;
  text-transform: capitalize;
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.1), 0px 1px 3px 0px rgba(0, 0, 0, 0.08);
}

.non-style-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.flex-4d {
  flex: 4;
}
.flex-1d {
  flex: 1;
}
</style>
