<template>
  <div>
    <div v-if="isLoading" class="center-center fade-in">
      <ion-spinner class="center-center spinner-large" name="lines" color="dark"></ion-spinner>
    </div>
    <div class="pad-top-twenty" v-else>
      <div :class="isCarded ? 'scrollable' : ''" style="padding-bottom: 30px;">
        <div>
          <ion-row v-if="!isCarded">
            <ion-col class="margin-bottom-ten">
              <h3>Quality Considerations</h3>
            </ion-col>
            <ion-col size="2" v-if="cdeFormsId.length > 0">
              <button v-if="$can(I.ADD_CDE_REQUEST)" type="button" @click="bulkCdeEntry"
                class="prime-button button-primary button-block">CDE Bulk Entry</button>
              <button v-else type="button" class="prime-button button-primary button-block disabled"
                v-tooltip.bottom-start="{ content: $orgMissingPermissions('The Modify CDE feature') }">CDE Bulk
                Entry</button>
            </ion-col>
          </ion-row>
          <ion-row v-if="!Object.keys(gaps).length">
            <ion-col class="display-flex valign center-center no-programs">
              <div class="text-muted">
                No Quality Considerations currently available.
              </div>
            </ion-col>
          </ion-row>

          <ion-row class="gaps-rows" v-for="(value, key) in displayedData" :key="key">
            <!-- Render the initial list of items in the payload -->
            <ion-col>
              <h5 v-if="!isCarded">{{ key }}</h5>
              <ion-row v-for="(item, index) in value" :key="index" class="overflow-visible">
                <QualityCard :item="item" :isCarded="isCarded" :key="reOrder" :beneId="beneId"
                  :isMarkedStatusEnabled="isMarkedStatusEnabled" :canUpdateGapsInCare="canUpdateGapsInCare"
                  :currentPatient="currentPatient" />
              </ion-row>
            </ion-col>
          </ion-row>

          <div v-if="!isCarded">
            <div v-if="this.getFilteredData.length && (addressedArray.length || notAddressedArray.length)"
              class="bottom-divider"></div>
            <!-- Render a list of items that have been Addressed -->
            <ion-row v-if="addressedArray.length" class="addressed-data">
              <ion-col>
                <h5 class="center-items">
                  <ion-icon name="md-checkmark-circle" color="success"></ion-icon>Addressed
                  <div class="indicates">
                    <InformationCircle />
                  </div>
                </h5>
                <ion-row v-for="(item, index) in addressedArray" :key="index">
                  <QualityCard :item="item" :isCarded="isCarded" :key="reOrder" :beneId="beneId"
                    :isMarkedStatusEnabled="isMarkedStatusEnabled" :canUpdateGapsInCare="canUpdateGapsInCare"
                    :currentPatient="currentPatient" />
                </ion-row>
              </ion-col>
            </ion-row>

            <!-- Render a list of items that have been Not Addressed -->
            <ion-row v-if="notAddressedArray.length" class="addressed-data">
              <ion-col>
                <h5 class="center-items">
                  <ion-icon name="md-close-circle" color="danger"></ion-icon>Not Addressed
                  <div class="indicates">
                    <InformationCircle />
                  </div>
                </h5>
                <ion-row v-for="(item, index) in notAddressedArray" :key="index">
                  <QualityCard :item="item" :isCarded="isCarded" :key="reOrder" :beneId="beneId"
                    :isMarkedStatusEnabled="isMarkedStatusEnabled" :canUpdateGapsInCare="canUpdateGapsInCare"
                    :currentPatient="currentPatient" />
                </ion-row>
              </ion-col>
            </ion-row>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { addIcons } from "ionicons";
import { send as httpSend } from "@/services/Api";
import { more, checkmarkCircle } from "ionicons/icons";
import { EventBus } from "@/services/Events.js";
import InformationCircle from "@/components/Global/Icons/InformationCircle";
import IconCopy from "@/components/Global/Icons/IconCopy";
import IconEditPencilFramed from "@/components/Global/Icons/IconEditPencilFramed.vue";
import QualityCard from "./modules/QualityCard.vue";

addIcons({
  "md-more": more.md,
  "md-checkmark-circle": checkmarkCircle.md,
});

export default {
  name: "GapsInCare",
  props: {
    currentPatient: Object,
    isCarded: {
      required: false,
      type: Boolean
    }
  },
  components: {
    IconEditPencilFramed,
    IconCopy,
    InformationCircle,
    QualityCard
  },
  data() {
    return {
      gaps: {},
      getFilteredData: {},
      getCombinedData: {},
      isMarkedStatusEnabled: true,
      canUpdateGapsInCare: true,
      addressedArray: [],
      notAddressedArray: [],
      isLoading: false,
      beneId: null,
      cdeFormsId: [],
      cdeItems: [],
      reOrder: true
    };
  },
  async beforeMount() {
    this.isLoading = true;
    EventBus.$on("initializeListIndicators", () => {
      this.reOrder = !this.reOrder;
    });
  },
  async mounted() {
    this.beneId = this.$store.state.chatProfessional.currentPatient.beneficiary.id;
    this.getGaps();
    EventBus.$on("updateNotesCount", evt => {
      for (let k in this.gaps) {
        let foundIndex = this.gaps[k].findIndex(gap => gap.gapId === evt.gapId);
        if (foundIndex > -1) {
          let newObj = { ...this.gaps[k].filter(gap => gap.gapId === evt.gapId)[0], countOfNotes: evt.notesCount };
          this.gaps[k][foundIndex] = newObj;
          this.$forceUpdate();
        }
      }
    });
    EventBus.$on("updateStatus", () => {
      this.getGaps();
    });
    EventBus.$on("updateQualityConsideration", async () => {
      const allModals = document.querySelectorAll("ion-modal");
      allModals.forEach(ele => {
        ele.remove();
      });
      await this.getGaps();
    });
  },
  computed: {
    displayedData() {
      return this.isCarded ? this.getCombinedData : this.getFilteredData;
    }
  },
  methods: {
    bulkCdeEntry() {
      this.$router.push({
        name: "quality-considerations-bulk",
        params: { formIds: this.cdeFormsId, cdeItems: this.cdeItems, isCarded: true }
      });
    },
    getNotesCount(id) {
      for (let k in this.gaps) {
        let found = this.gaps[k].find(gap => gap.cdsId === id);
        if (found) {
          return this.gaps[k].filter(gap => gap.cdsId === id)[0].countOfNotes;
        }
      }
    },
    async getGaps() {
      this.gaps = {};
      this.getFilteredData = {};
      this.getCombinedData = {};
      this.cdeFormsId = [];
      this.cdeItems = [];
      this.isLoading = true;
      this.addressedArray = [];
      this.notAddressedArray = [];
      const method = "get";
      const path = document.config.gapsInCare + this.beneId;
      const isAddressed = x => x && x.markedStatus && x.markedStatus.toLowerCase() === "addressed";
      const isNotAddressed = x => x && x.markedStatus && x.markedStatus.toLowerCase() === "not_addressed";
      const isNullStatus = x => {
        const lowercaseStatus = x && x.markedStatus ? x.markedStatus.toLowerCase() : null;
        return lowercaseStatus === "not_specified" || lowercaseStatus === null;
      };
      await httpSend({ path, method, authToken: true })

        .then(response => {
          const responseObject = response.data;
          this.getCombinedData = JSON.parse(JSON.stringify(responseObject.gapsInCares));
          this.gaps = responseObject.gapsInCares;
          this.isMarkedStatusEnabled = responseObject.isMarkedStatusEnabled
          this.canUpdateGapsInCare = responseObject.isMarkedStatusEditable

          this.isLoading = false;
          for (let key in this.gaps) {
            this.gaps[key].forEach(item => {
              if (item.cdeFormId && item.cdeStatus === "ready_for_entry") {
                this.cdeFormsId.push(item.cdeFormId);
                this.cdeItems.push(item);
              }
              if (isAddressed(item)) {
                this.addressedArray.push(item);
              } else if (isNotAddressed(item)) {
                this.notAddressedArray.push(item);
              }
            });
          }
          for (let key in this.gaps) {
            this.gaps[key] = this.gaps[key].filter(item => isNullStatus(item));
          }

          this.getFilteredData = this.filteredGapsValue(this.gaps)
        })
        .catch(error => {
          this.isLoading = false;
          this.$ionic.toastController
            .create({
              header: "Failed to load Quality Considerations",
              message: error,
              duration: 7000,
              position: "top"
            })
            .then(m => m.present());
          khanSolo.log(error);
        });
    },

    filteredGapsValue(gaps) {
      let resultObject = {};
      if (gaps && typeof gaps === 'object') {
        const nonEmptyKeys = Object.keys(gaps).filter(key => {
          return Array.isArray(gaps[key]) && gaps[key].length > 0;
        });
        if (nonEmptyKeys.length > 0) {
          nonEmptyKeys.forEach(key => {
            resultObject[key] = gaps[key];
          });
        }
      }
      return resultObject;
    },

    getCdeStatus(cdeStatus) {
      const cdeStatusDictionary = {
        ready_for_entry: { cssClass: "pill-blue gray-pill", text: "Ready for Entry" },
        first_review_needed: { cssClass: "gray-pill review-needed", text: "First Review Needed" },
        second_review_needed: { cssClass: "gray-pill review-needed", text: "Second Review Needed" },
        in_first_review: { cssClass: "gray-pill review-needed", text: "Being Reviewed" },
        in_second_review: { cssClass: "gray-pill review-needed", text: "Being Reviewed" },
        approved: { cssClass: "pill-wrap-green", text: "Approved" },
        rejected: { cssClass: "pill-wrap-urgent", text: "Rejected" }
      };

      return {
        displayName: cdeStatusDictionary[cdeStatus].text,
        displayClass: cdeStatusDictionary[cdeStatus].cssClass,
        displayText: cdeStatusDictionary[cdeStatus].text
      };
    }
  }
};
</script>
<style scoped lang="scss">
#app h6 {
  font-size: 13px;
}

prime-channel-tabs-container {
  width: 200px;
}

.addressed-data {
  .center-items {
    display: flex;
    align-items: center;
  }

  ion-icon {
    margin-right: 10px;
    width: 25px;
    height: 25px;

    .icon-inner {
      svg {
        fill: var(--ion-color-success);
      }
    }
  }
}

button.prime-button.button-clear {
  padding-left: 4px;
  padding-right: 4px;
  font-size: 18px;
}

.no-programs {
  height: 100px;
}

.indicates {
  margin-left: 5px;
  height: 20px;
}

.indicates svg {
  width: 20px;
  height: 20px;
}

.name {
  padding: 0 2rem;
}

.fact-label {
  white-space: nowrap;
}

.template-span {
  margin-top: 2px;
  display: flex;
}

.parte-card {
  list-style-type: none;
  display: flex;
  margin: 0;
  margin-top: -3px;
  padding: 0;
}

.notes {
  color: var(--ion-color-medium-shade);
  font-weight: normal;
}

.cust-badge {
  border: 3px solid var(--ion-color-medium-shade);
  width: 2rem;
  height: 2rem;
  color: var(--ion-color-medium-shade);
  text-align: center;
  border-radius: 50%;
  font-weight: bold;
}

.notes-count {
  background: var(--ion-color-primary);
  border: 3px solid var(--ion-color-primary);
  color: var(--ion-color-white);
}

.pill {
  min-width: 85px;
  line-height: 80%;
  font-size: 10.3px;
  color: black;
  margin-top: 0;
}

.pill-blue {
  color: white;
  line-height: 80%;
  padding-right: 10px;
}

.pill-sm {
  margin-top: 1px;
}

.gray-pill {
  min-width: 135px;
}

h5 {
  margin-top: 30px;
}

.maxed-col {
  max-width: 70%;
}

.flatten-card,
.flatten-card ion-card-content,
.flatten-card {
  all: revert;
  cursor: pointer;
}

.flatten-card ion-grid {
  margin-bottom: 20px;
  border-bottom: 1px solid #e2e2e2;
  padding-bottom: 15px;
}

.flatten-card ion-col {
  padding: 0 0 0 10px !important;
}

.flatten-card .maxed-col {
  max-width: 85%;
}

.scrollable {
  max-height: 432px;
  overflow-y: auto;
  background:
    /* Shadow covers */
    linear-gradient(white 30%, rgba(255, 255, 255, 0)),
    linear-gradient(rgba(255, 255, 255, 0), white 70%) 0 100%,
    /* Shadows */
    radial-gradient(50% 0, farthest-side, rgba(0, 0, 0, .2), rgba(0, 0, 0, 0)),
    radial-gradient(50% 100%, farthest-side, rgba(0, 0, 0, .2), rgba(0, 0, 0, 0)) 0 100%;
  background:
    /* Shadow covers */
    linear-gradient(white 30%, rgba(255, 255, 255, 0)),
    linear-gradient(rgba(255, 255, 255, 0), white 70%) 0 100%,
    /* Shadows */
    radial-gradient(farthest-side at 50% 0, rgba(0, 0, 0, .2), rgba(0, 0, 0, 0)),
    radial-gradient(farthest-side at 50% 100%, rgba(0, 0, 0, .2), rgba(0, 0, 0, 0)) 0 100%;
  background-repeat: no-repeat;
  background-color: white;
  background-size: 100% 40px, 100% 40px, 100% 14px, 100% 14px;
  /* Opera doesn't support this in the shorthand */
  background-attachment: local, local, scroll, scroll;


}

.review-needed {
  border: 2px solid rgb(255, 164, 61);
}

.pill-wrap-green {
  background-color: #70c01c;
  min-width: 100px;
  color: #fff;
}

.pill-wrap-urgent {
  background-color: #e02020;
  min-width: 100px;
  color: #fff;
}

.option-note {
  align-self: end;
  padding: 5px 10px 3px;
}

.gaps-rows:last-child {
  padding-bottom: 30px;
}
</style>
.bottom-divider {
margin: 40px 0 0px;
}