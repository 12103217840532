<template>
  <div class="prime-modal">
    <ion-header>
      <ion-toolbar>
        <IconChatBubble slot="start" class="icon-small" />
        <h5 slot="start">{{ title }}</h5>
        <ion-button slot="end" mode="ios" @click="closeModal(null)" expand="full" color="primary" fill="clear"><ion-icon name="md-close"></ion-icon></ion-button>
      </ion-toolbar>
    </ion-header>
    <!-- notifications alerts -->
    <div class="margin-lr-twenty prime-modal-patient-results-notifications" v-if="notifications.length">
      <div v-if="notifications.length > 0" :class="{ 'danger-well': isError }" class="margin-bottom-ten notifications-well">
        <div v-for="(notification, key) in notifications" :key="key">
          {{ notification }}
        </div>
      </div>
    </div>
    <div v-if="!selectedPatient" class="prime-modal-content">
      <h6>Search for a patient to message:</h6>
      <input class="form-control margin-bottom-ten" type="text" placeholder="Search all patients..." v-model="searchCriteria" />
    </div>
    <div v-if="selectedPatient" class="prime-modal-content patient-selected">
      <h6>Message recipient</h6>
      <ion-card v-for="(patient, key) in patientSearchResults" :key="key" class="prime-patient-card ion-margin-bottom">
        <div class="avatar" v-if="patient.beneFirstName && patient.beneLastName">
          {{ patient.beneFirstName.charAt(0) + patient.beneLastName.charAt(0) }}
        </div>
        <div>
          <div class="prime-patient-card-name">
            {{ patient.beneLastName + ", " + patient.beneFirstName }}
          </div>
          <div class="prime-patient-card-info">
            <strong>ID: </strong><span class="mrn-holder">{{ patient.mrn }}</span>
            <div class="display-inline-block"><strong>PCP: </strong>{{ patient.pcpTitle | formatDisplayName(patient.pcpFirstName, patient.pcpLastName, patient.suffix) }}</div>
          </div>
        </div>
        <div class="prime-patient-card-icons">
          <IconChatBubbleDisabled v-if="patient.activeConversation" class="icon-blue icon-small" v-tooltip.right="'This patient is currently in an active conversation.<br>No new conversation can be started until the <br>current conversation is complete.'" />
          <IconMoon v-if="patient.doNotDisturb" class="icon-blue icon-small" v-tooltip.right="'Patient is currently in &quot;Do Not Disturb&quot;'" />
        </div>
      </ion-card>
      <div v-if="selectedPatient" class="login-form">
        <h6>Conversation Subject:</h6>
        <div class="form-sub-label">
          For your reference; will not be shown to patient.
        </div>
        <input class="form-control" type="text" placeholder="Type Subject here..." v-model="outboundMessageTitle" :maxlength="maxTitleChars" />
        <h6>Message:</h6>
        <textarea placeholder="Type message here..." class="form-control" rows="7" v-model="outboundMessage"></textarea>

        <div class="margin-top-ten" :class="{ 'button-group': !isLoading }">
          <button :class="{ hidden: isLoading }" class="prime-button dropdown-button" @click="showSecureSelector">
            <span><ion-icon name="md-arrow-dropdown" size="med" color="white"></ion-icon></span>
          </button>
          <button class="prime-button button-block" @click="checkForm" :class="{ 'button-pending': isLoading }">
            <span v-if="msgChannel == 'zeus'">Send as Secure Chat<ion-icon name="md-arrow-up" size="med" color="white"></ion-icon></span>
            <span v-if="msgChannel == 'sms' && !this.dbuggaMode">Send as Text Message<ion-icon name="md-arrow-up" size="med" color="white"></ion-icon></span>
            <span v-if="this.dbuggaMode">Send as SMS with Debugger<ion-icon name="md-arrow-up" size="med" color="white"></ion-icon></span>
            <ion-spinner name="dots" duration="1200" />
          </button>
        </div>
      </div>
    </div>
    <ion-content v-if="!selectedPatient" class="prime-patient-results-container" ref="prime-content-lazy-load" :scroll-events="true" @ionScroll="scrollListener($event)">
      <div v-if="isLoadingPatients">
        <div class="centerItems text-center display-flex valign full-height margin-top-twenty full-width">
          <span>
            <ion-spinner name="lines" class="spinner-medium" color="primary"></ion-spinner>
          </span>
        </div>
      </div>

      <div class="prime-modal-patient-results">
        <div v-if="patientSearchResults.length > 0">
          <ion-card v-for="(patient, key) in patientSearchResults" :key="key" @click="selectPatient(patient)" class="prime-patient-card">
            <div class="avatar" v-if="patient.beneFirstName && patient.beneLastName">
              {{ patient.beneFirstName.charAt(0) + patient.beneLastName.charAt(0) }}
            </div>
            <div>
              <div class="prime-patient-card-name">
                {{ patient.beneLastName + ", " + patient.beneFirstName }}
              </div>
              <div class="prime-patient-card-info">
                <strong>ID: </strong>
                <span class="mrn-holder">{{ patient.mrn }}</span>
                <div class="display-inline-block"><strong>PCP: </strong>{{ patient.pcpTitle | formatDisplayName(patient.pcpFirstName, patient.pcpLastName, patient.suffix) }}</div>
              </div>
            </div>
            <div class="prime-patient-card-icons">
              <IconChatBubbleDisabled v-if="patient.activeConversation" class="icon-blue icon-small" v-tooltip.right="'This patient is currently in an active conversation.<br>No new conversation can be started until the <br>current conversation is complete.'" />
              <IconMoon v-if="patient.doNotDisturb" class="icon-blue icon-small" v-tooltip.right="'Patient is currently in &quot;Do Not Disturb&quot;'" />
            </div>
          </ion-card>
        </div>
        <div class="centerItems text-center full-width">
          <IconListEnd v-if="endOfList && !isSearching && patientSearchResults.length > 1" class="icon-small icon-grey" />
          <ion-spinner v-else-if="isLoadingMorePatients" name="lines" class="spinner-small" color="primary"></ion-spinner>
        </div>
      </div>
    </ion-content>
  </div>
</template>

<script>
import { send as httpSend } from "@/services/Api";
import { EventBus } from "@/services/Events.js";
import _ from "lodash";
import { addIcons } from "ionicons";
import { close, arrowDropdown } from "ionicons/icons";
import IconListEnd from "@/components/Global/Icons/IconListEnd";

import PopoverSecureMsgSelector from "@/components/Chat/PopoverSecureMsgSelector";
import IconChatBubble from "@/components/Global/Icons/IconChatBubble";
import IconMoon from "@/components/Global/Icons/IconMoon";
import IconChatBubbleDisabled from "@/components/Global/Icons/IconChatBubbleDisabled";
import store from "@/store";

addIcons({
  "md-close": close.md,
  "md-arrow-dropdown": arrowDropdown.md
});

export default {
  name: "ModalNewMessage",
  components: {
    IconChatBubble,
    IconChatBubbleDisabled,
    IconMoon,
    IconListEnd
  },
  computed: {
    dbuggaMode() {
      return store.getters["dbugga/dbuggaMode"];
    }
  },
  props: {
    title: { type: String, default: "Super Modal" }
  },
  data() {
    return {
      searchCriteria: undefined,
      patientSearchResults: [],
      pageNumber: 1,
      perPage: 50,
      endOfList: false,
      selectedPatient: undefined,
      selectedAccount: undefined,
      outboundMessageTitle: undefined,
      outboundMessage: undefined,
      interactionId: undefined,
      notifications: [],
      isError: false,
      isLoading: false,
      isLoadingPatients: false,
      isLoadingMorePatients: false,
      pageCopy: this.$languagePack,
      msgChannel: "sms",
      maxTitleChars: 255,
      isSearching: false,
      isScrollListening: true
    };
  },
  watch: {
    searchCriteria: function() {
      this.endOfList = false;
      if (this.searchCriteria.length == 0) {
        this.isSearching = false;
        this.isScrollListening = true;
      } else if (this.searchCriteria && this.searchCriteria.length >= 1) {
        this.notifications = [];
        this.debounceInput();
        this.isSearching = true;
        this.isScrollListening = false;
      }
    }
  },
  mounted: function() {
    this.loadPatients(false);
  },
  created: function() {
    EventBus.$on("messageChannel", this.setMessagechannel);
  },
  methods: {
    scrollListener() {
      this.$refs["prime-content-lazy-load"].getScrollElement().then(scrollElement => {
        if (scrollElement.scrollHeight == Math.floor(scrollElement.scrollTop + scrollElement.clientHeight) && !this.isLoadingMorePatients && !this.endOfList && this.isScrollListening) {
          this.pageNumber++;
          this.loadPatients(true);
        }
      });
    },
    toggleSecureMessage() {},
    debounceInput: _.debounce(function() {
      this.searchPatients(false);
    }, 400),
    async loadPatients(morePatients) {
      this.isSearching = false;
      morePatients ? (this.isLoadingMorePatients = true) : (this.isLoadingPatients = true);

      const method = "post";
      const claims = await this.$authState.claims();
      const path = document.config.patientsApi + claims.professionalId;

      var payload = {
        page: this.pageNumber || 1,
        perPage: this.perPage,
        columnFilters: {
          beneLastName: this.searchCriteria,
          beneFirstName: this.searchCriteria,
          mrn: this.searchCriteria,
          dob: this.searchCriteria,
          active: "true",
          orgId: this.$authState.getOrgId() ? this.$authState.getOrgId() : claims.orgIds
        },
        sort: [{ field: "beneLastName", type: "asc" }]
      };

      httpSend({ path, method, authToken: true, payload })
        .then(result => {
          if (result.data.results.length < this.perPage) {
            this.patientSearchResults = [...this.patientSearchResults, ...result.data.results];
            this.endOfList = true;
          } else {
            this.patientSearchResults = [...this.patientSearchResults, ...result.data.results];
            this.endOfList = false;
          }
          this.isLoadingPatients = false;
          this.isLoadingMorePatients = false;
        })
        .catch(error => {
          this.isLoadingPatients = false;
          this.isLoadingMorePatients = false;
          khanSolo.log(error);
        });
    },

    async searchPatients() {
      this.isLoadingPatients = true;
      const method = "post";
      const claims = await this.$authState.claims();
      const path = document.config.patientsApi + claims.professionalId;

      var payload = {
        page: "1",
        perPage: "50",
        columnFilters: {
          beneLastName: this.searchCriteria,
          beneFirstName: this.searchCriteria,
          mrn: this.searchCriteria,
          dob: this.searchCriteria,
          active: "true",
          orgId: this.$authState.getOrgId() ? this.$authState.getOrgId() : claims.orgIds
        },
        sort: [{ field: "beneLastName", type: "asc" }]
      };

      httpSend({ path, method, authToken: true, payload })
        .then(result => {
          this.patientSearchResults = result.data.results;
          this.isLoadingPatients = false;
        })
        .catch(error => {
          this.isLoadingPatients = false;
          khanSolo.log(error);
        });
    },

    selectPatient: function(patient) {
      this.notifications = [];
      if (patient.activeConversation && patient.doNotDisturb) {
        this.notifications.push(this.pageCopy.messagePanel.notifications.activeConvo);
        this.notifications.push(this.pageCopy.messagePanel.notifications.doNotDisturb);
      } else if (patient.doNotDisturb) {
        this.notifications.push(this.pageCopy.messagePanel.notifications.doNotDisturb);
      } else if (patient.activeConversation) {
        this.notifications.push(this.pageCopy.messagePanel.notifications.activeConvo);
      } else {
        // wack the search Results list in favor of selected patient
        this.patientSearchResults = [];
        this.patientSearchResults.push(patient);
        // set selected patient stuffs
        this.selectedPatient = patient.beneficiaryId;
        this.selectedAccount = patient.accountId;
      }
    },
    initConvo: async function(that) {
      return new Promise(async function(resolve, reject) {
        const method = "post";
        const claims = await that.$authState.claims();
        const payload = {
          beneficiary_id: that.selectedPatient,
          initiator: "conversation",
          urgency: "medium",
          professional_id: claims.professionalId,
          status: "active",
          subject: that.outboundMessageTitle || "untitled message"
        };
        const path = document.config.messageApi;

        httpSend({ path, payload, method, authToken: true })
          .then(result => {
            resolve(result.data.id);
          })
          .catch(error => {
            khanSolo.log(error);
            reject(error);
            this.isError = true;
            this.notifications.push(this.pageCopy.messagePanel.errors.general + error);
          });
      });
    },
    sendMessage: async function() {
      this.isLoading = true;

      if (!this.interactionId) {
        this.interactionId = await this.initConvo(this);
      }

      const method = "post";
      const claims = await this.$authState.claims();
      const payload = {
        interaction_id: this.interactionId,
        subject: null,
        body: this.outboundMessage,
        created_at: Math.floor(new Date().getTime()),
        professional_id: claims.professionalId,
        account_id: this.selectedAccount,
        external_correlation_id: 1,
        channel: this.msgChannel,
        urgency: "medium",
        direction: "outgoing",
        initiator: "conversation",
        status: "pending",
        source: "professional",
        provider: this.isDbugga && this.dbuggaMode ? "dbugga" : ""
      };
      const headers = { "Content-Type": "application/json" };
      const path = document.config.messageUpdateApi.professional;

      httpSend({ path, method, authToken: true, headers, payload })
        .then(() => {
          this.closeModal(this.interactionId);
          this.isLoading = false;
        })
        .catch(() => {
          this.isLoading = false;
        });
    },
    closeModal(intId) {
      if (intId) {
        EventBus.$emit("newInteraction", intId, this.msgChannel);
        this.$ionic.modalController.dismiss();
      } else {
        this.$ionic.modalController.dismiss();
      }
    },
    checkForm: function(e) {
      this.notifications = [];

      if (!this.outboundMessage) {
        this.isError = true;
        this.notifications.push(this.pageCopy.messagePanel.errors.msgBodyMissing);
      }
      if (!this.notifications.length) {
        this.sendMessage();
      }
      e.preventDefault();
    },
    showSecureSelector(ev) {
      this.$ionic.popoverController
        .create({
          component: PopoverSecureMsgSelector,
          // uncomment for popover snap to element
          event: ev,
          // cssClass: 'popover-width-md',
          mode: "ios",
          componentProps: {
            data: {
              msgChannel: this.msgChannel
            }
          }
        })
        .then(m => m.present());
    },
    setMessagechannel(channel) {
      this.msgChannel = channel;
    }
  }
};
</script>

<style scoped>
.prime-modal .prime-modal-content.patient-selected {
  height: 100%;
  padding-bottom: 20px;
  border-bottom: none;
}
.prime-modal-patient-results {
  padding: 20px 0 10px;
}
.prime-modal ion-content.prime-patient-results-container {
  --background: var(--ion-modal-secondary-background);
}
.prime-modal ion-content.prime-patient-results-container .prime-modal-patient-results-notifications {
  margin: 0 20px;
}
.prime-modal-patient-results small {
  font-size: 12px;
  font-weight: 500;
  text-align: center;
  display: block;
  color: var(--ion-color-medium-shade);
  margin: 20px 0 10px;
}
ion-card.prime-patient-card {
  background: #ffffff;
  color: var(--ion-text-color);
  display: flex;
  align-items: center;
  margin: 0 0 10px 0 !important;
  box-shadow: 0 1px 6px 0px rgba(46, 61, 73, 0.2) !important;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}
ion-card.prime-patient-card:hover {
  box-shadow: 0 3px 15px 0px rgba(46, 61, 73, 0.3) !important;
}
ion-card.prime-patient-card .avatar {
  min-width: 60px;
  height: 60px;
  font-size: 18px;
  margin-left: 10px;
  margin-right: 20px;
}
ion-card.prime-patient-card .prime-patient-card-name {
  font-size: 16px;
  font-weight: 500;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
ion-card.prime-patient-card .prime-patient-card-info {
  font-size: 12px;
  font-weight: 500;
  margin-top: 2px;
  color: var(--ion-color-medium-shade);
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

ion-card.prime-patient-card .prime-patient-card-icons {
  display: flex;
  position: absolute;
  right: 20px;
  top: 15px;
}
ion-card.prime-patient-card .prime-patient-card-icons img {
  width: 22px;
  margin: 0 0 0 10px;
}
.prime-modal-content.patient-selected .prime-patient-card {
  margin-bottom: 25px !important;
}
.prime-modal-content.patient-selected {
  overflow-y: auto;
}
.prime-modal-content.patient-selected .form-sub-label {
  font-size: 12px;
  font-weight: 500;
  margin-top: -8px;
  margin-bottom: 5px;
  color: var(--ion-color-medium-shade);
}
.dropdown-button {
  width: 60px;
}
.button-group {
  display: flex;
}
.button-group .prime-button:first-child {
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  border-right: 1px solid #347aca;
}
.button-group .prime-button:last-child {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  border-left: 1px solid #184f8d;
}
.mrn-holder {
  display: inline-block;
  max-width: 240px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  vertical-align: bottom;
}
.mrn-holder::after {
  content: " ";
  margin-right: 5px;
}
</style>
